import {useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeFilters,
	clearFilters,
	changeOffset,
	IWarrantyClaimsFiltersState
} from '@src/store/modules/settings/pages/warrantyClaims/slices';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const WarrantyClaimsFiltersRoutingPage = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();
	const {filters} = useAppSelector(s => extractWarrantyClaimsPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const applyFilters = useCallback(
		(value: IWarrantyClaimsFiltersState) => {
			dispatch(changeFilters({objectId: objectId || 'all', filters: value}));
			dispatch(changeOffset({objectId: objectId || 'all', offset: 0}));
		},
		[objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: objectId || 'all'}));
	}, [dispatch, objectId, clearFilters]);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};

export default WarrantyClaimsFiltersRoutingPage;
