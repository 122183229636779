import {EditorInfo, EntityGrid, EntityGridItem, Plate} from '@tehzor/ui-components';
import {excelXmlType, pdfType, wordXmlType} from '@tehzor/tools/utils/mimeTypes';
import {Temp} from './desktop/Temp';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';
import {DocumentLinks} from './documentLinks/DocumentLinks';
import ObjectsList from './objectsList/ObjectsList';

const nameIcon = <i className="tz-name-20" />;
const typeIcon = <i className="tz-document-type-20" />;
const formatIcon = <i className="tz-document-format-20" />;
const numberIcon = <i className="tz-number-20" />;
const exportTemplateIcon = <i className="tz-export-template-20" />;
const contractorIcon = <i className="tz-contractor-20" />;
const customerIcon = <i className="tz-customer-20" />;
const addedIcon = <i className="tz-added-20" />;
const objectsIcon = <i className="tz-outline-object-20" />;

const fileTypeNames = {
	[wordXmlType]: 'docx',
	[excelXmlType]: 'xlsx',
	[pdfType]: 'pdf'
};

interface IDesktopProps {
	document?: IDocument;
}

export const Desktop = ({document}: IDesktopProps) => {
	if (!document) {
		return null;
	}

	return (
		<div className="document-page__plates">
			<div className="document-page__plates--info">
				<DocumentLinks document={document} />
				<Plate className={{content: 'document-page__info-plate'}}>
					<div className="document-page__title">Информация</div>
					<EntityGrid withBorders>
						{document.fileName && (
							<EntityGridItem
								label="Название"
								fullRow
								icon={nameIcon}
							>
								<p className="document-page__entity-paragraph">
									{document.fileName}
								</p>
							</EntityGridItem>
						)}

						{document.type?.name && (
							<EntityGridItem
								label="Тип документа"
								fullRow
								icon={typeIcon}
							>
								<p className="document-page__entity-paragraph">
									{document.type.name}
								</p>
							</EntityGridItem>
						)}

						<EntityGridItem
							label="Номер"
							fullRow
							icon={numberIcon}
						>
							<p className="document-page__entity-paragraph">{document.number}</p>
						</EntityGridItem>

						{document.fileType && fileTypeNames[document.fileType] && (
							<EntityGridItem
								label="Формат документа"
								fullRow
								icon={formatIcon}
							>
								<p className="document-page__entity-paragraph">
									{fileTypeNames[document.fileType]}
								</p>
							</EntityGridItem>
						)}

						{document.exportTemplate?.name && (
							<EntityGridItem
								label="Шаблон экспорта"
								fullRow
								icon={exportTemplateIcon}
							>
								<p className="document-page__entity-paragraph">
									{document.exportTemplate.name}
								</p>
							</EntityGridItem>
						)}

						{document.company?.name && (
							<EntityGridItem
								label="Компания-заказчик"
								fullRow
								icon={customerIcon}
							>
								<p className="document-page__entity-paragraph">
									{document.company.name}
								</p>
							</EntityGridItem>
						)}

						{document.contractor?.name && (
							<EntityGridItem
								label="Компания-подрядчик"
								fullRow
								icon={contractorIcon}
							>
								<p className="document-page__entity-paragraph">
									{document.contractor.name}
								</p>
							</EntityGridItem>
						)}

						{document.objects && document.objects.length > 0 && (
							<EntityGridItem
								label="Объекты"
								fullRow
								icon={objectsIcon}
							>
								<ObjectsList objects={document.objects} />
							</EntityGridItem>
						)}

						{document.createdAt && document.createdByUser && (
							<EditorInfo
								icon={addedIcon}
								label="Сформировано"
								date={new Date(document.createdAt).getTime()}
								user={document.createdByUser}
								fullRow
							/>
						)}
					</EntityGrid>
				</Plate>
			</div>
			<Plate
				className={{
					root: 'document-page__preview-plate',
					content: 'document-page__preview-plate-content'
				}}
			>
				<Temp />
				<div className="document-page__preview-message">Интерфейс на стадии доработки</div>
			</Plate>
		</div>
	);
};
