import {useCallback, useState, ReactNode} from 'react';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import {useExportSpace} from '../mutations/spaces/useExportSpace';

const availableDestinations = [ExportTemplatesDestinationId.SPACE];

export function useSpaceExport(
	objectId: string,
	spaceId: string
): [ReactNode, (id: string, sid: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const [exportingSpaceId, setExportingSpaceId] = useState<string>();
	const [isOpen, setOpen] = useState<boolean>(false);
	const {mutateAsync: exportSpace} = useExportSpace();

	const openDialog = useCallback((id: string, sid: string) => {
		setExportingId(id);
		setExportingSpaceId(sid);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setExportingSpaceId(undefined);
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (exportingSpaceId && exportingId) {
				await exportSpace({
					templateId: template.id,
					createDocument,
					email,
					objectId: exportingId,
					spaceId: exportingSpaceId
				});
			}
		},
		[objectId, spaceId, exportingSpaceId]
	);

	const dialog = isOpen ? (
		<ExportDialog
			objectId={objectId}
			pageKey="space"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	) : null;

	return [dialog, openDialog];
}
