// eslint-disable-next-line import/no-extraneous-dependencies
import * as React from 'react';
import './Calendar.less';
import DaysGrid from './components/DaysGrid';
import DaysNav from './components/DaysNav';
import WeekNames from './components/WeekNames';
import {CalendarView, useCalendarView} from './hooks/useCalendarView';
import MonthsNav from './components/MonthsNav';
import MonthsGrid from './components/MonthsGrid';
import YearsGrid from './components/YearsGrid';
import YearsNav from './components/YearsNav';
import DecadesGrid from './components/DecadesGrid';
import DecadesNav from './components/DecadesNav';
import classNames from 'classnames';
import {IDayProps} from './components/Day';
import {IWeekRowProps} from './components/WeekRow';
import {Locale} from 'date-fns';

export interface IDatesOptions {
	locale?: Locale;
	weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
	firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
	isDayStartFromEnd?: boolean;
}

interface ICalendarProps {
	className?: string;
	style?: React.CSSProperties;
	initialDate?: Date;
	datesOptions?: IDatesOptions;
	weekRowComponent?: React.ReactElement<IWeekRowProps> | ((props: IWeekRowProps) => JSX.Element);
	dayComponent?: React.ReactElement<IDayProps> | ((props: IDayProps) => JSX.Element);
}

const Calendar = (props: ICalendarProps) => {
	const {
		className,
		style,
		initialDate = new Date(),
		datesOptions,
		weekRowComponent,
		dayComponent
	} = props;

	const viewData = useCalendarView(initialDate);
	const {view, month, year, decade, century} = viewData;

	return (
		<div
			className={classNames('calendar', className)}
			style={style}
		>
			{view === CalendarView.MONTH && month !== undefined && year !== undefined && (
				<>
					<DaysNav
						viewData={viewData}
						datesOptions={datesOptions}
					/>
					<WeekNames datesOptions={datesOptions}/>
					<DaysGrid
						month={month}
						year={year}
						datesOptions={datesOptions}
						weekRowComponent={weekRowComponent}
						dayComponent={dayComponent}
					/>
				</>
			)}

			{view === CalendarView.YEAR && (
				<>
					<MonthsNav viewData={viewData}/>
					<MonthsGrid
						month={month}
						datesOptions={datesOptions}
						changeMonth={viewData.changeMonth}
					/>
				</>
			)}

			{view === CalendarView.DECADE && decade !== undefined && (
				<>
					<YearsNav viewData={viewData}/>
					<YearsGrid
						year={year}
						decade={decade}
						changeYear={viewData.changeYear}
					/>
				</>
			)}

			{view === CalendarView.CENTURY && (
				<>
					<DecadesNav viewData={viewData}/>
					<DecadesGrid
						decade={decade}
						century={century}
						changeDecade={viewData.changeDecade}
					/>
				</>
			)}
		</div>
	);
};

export default Calendar;
