import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Отправляет запрос на удаление гарантийного обращения
 */
export const makeWarrantyClaimDeleteRequest = async (objectId: string, claimId: string) => {
	const response = await httpRequests.withToken.delete<void>(`warranty-claims/${claimId}`, {
		params: {objectId}
	});

	return response.data;
};
