import {FullTextSearch} from '@tehzor/ui-components';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeSearchBy,
	IWarrantyClaimsFiltersState
} from '@src/store/modules/settings/pages/warrantyClaims/slices';
import {ICustomFieldSetting} from '@tehzor/tools/interfaces/fields';
import {getCustomFields} from '../../utils/getCustomFields';
import {warrantyClaimsFields} from './consts/warrantyClaimsFields';

interface IWarrantyClaimsSearchProps {
	objectId?: string;
	customFieldsSettings?: Record<string, ICustomFieldSetting>;
}

export const WarrantyClaimsSearch = ({
	objectId = 'all',
	customFieldsSettings
}: IWarrantyClaimsSearchProps) => {
	const {state, dispatch} = useEntitiesFiltersCtx<IWarrantyClaimsFiltersState>();
	const {filters, searchBy} = useAppSelector(s => extractWarrantyClaimsPageSettings(s, objectId));
	const {t} = useTranslation();
	const isDesktop = useIsDesktop();
	const appDispatch = useAppDispatch();

	const fields = useMemo(() => {
		const customFields = getCustomFields(customFieldsSettings);
		return [...warrantyClaimsFields, ...customFields];
	}, [customFieldsSettings]);

	const handleApplySearch = useCallback(
		(searchString: string, searchFieldId: string) => {
			if (searchString) {
				dispatch({searchString, searchFieldId});
			}
		},
		[dispatch]
	);

	const handleClear = useCallback(
		(searchFieldId: string | undefined) => {
			dispatch({searchString: undefined, searchFieldId: undefined});
			if (searchFieldId) {
				appDispatch(changeSearchBy({objectId, searchBy: searchFieldId}));
			}
		},
		[dispatch, appDispatch, objectId]
	);

	const handleFieldChange = useCallback(
		(searchFieldId: string) => {
			appDispatch(changeSearchBy({objectId, searchBy: searchFieldId}));
		},
		[appDispatch, objectId]
	);

	return (
		<FullTextSearch
			isMobile={!isDesktop}
			placeholder={
				isDesktop
					? t('warrantyClaimsPage.search.placeholder.desktop')
					: t('warrantyClaimsPage.search.placeholder.mobile')
			}
			header={t('warrantyClaimsPage.search.header')}
			fields={fields}
			value={state.searchString}
			field={filters.searchFieldId || searchBy}
			onClear={handleClear}
			onSearch={handleApplySearch}
			onFieldChange={handleFieldChange}
		/>
	);
};
