import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';

export type IEditWarrantyClaimStatusResponse = IWarrantyClaim;

export interface IEditWarrantyClaimStatusRequest {
	objectId: string;
	claimId: string;
	statusId: WarrantyClaimStatusId;
}

/**
 * Изменяет статус гарантийного обращения
 */
export const makeWarrantyClaimStatusEditRequest = async ({
	objectId,
	claimId,
	statusId
}: IEditWarrantyClaimStatusRequest) => {
	const response = await httpRequests.withToken.put<IEditWarrantyClaimStatusResponse>(
		`warranty-claims/${claimId}/status`,
		{
			objectId,
			statusId
		}
	);

	return response.data;
};
