import {useMutation} from '@tanstack/react-query';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {makeInspectionExportRequest} from '@src/api/backend/inspection';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

interface IExportInspectionParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId: string;
	inspectionId: string;
}

export const useExportInspection = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation<IDefferedExportResponse, Error, IExportInspectionParams>({
		mutationKey: inspectionsQueryKeys.exportOne(),
		mutationFn: params => {
			const {templateId, createDocument, email, objectId, inspectionId} = params;
			return makeInspectionExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				inspectionId
			);
		},
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
