import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {getProblem} from '@src/store/modules/pages/problem/actions';
import {extractProblem} from '@src/store/modules/pages/problem/selectors/problem';
import {useIsDesktop, useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useEffect, useMemo} from 'react';
import {useAsync} from 'react-use';
import {useProblemSettings} from '@src/core/hooks/settings/useProblemSettings';
import './ProblemPage.less';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import Tablet from './components/Tablet';
import {usePermissions} from './hooks/usePermissions';
import {convertProblem} from './utils/convertProblem';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useTranslation} from 'react-i18next';
import {useProblemViewsAsArray} from '@src/core/hooks/queries/problemViews/hooks';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {useAddProblemView} from '@src/core/hooks/mutations/problemViews/useAddProblemView';
import {useProblemViewesByUser} from './hooks/useProblemViewesByUser';
import {MobileRightButtons} from './components/MobileRightButtons';

const ProblemPage = () => {
	const {t} = useTranslation();

	useScrollRestoration();
	const {objectId, problemId} = useStrictParams<{objectId: string; problemId: string}>();
	const settings = useProblemSettings(objectId);
	const extractedProblem = useAppSelector(extractProblem);
	const {data: usersMap} = useUsersAsMap();
	const user = useAppSelector(extractUserProfile);
	const {mutateAsync: addProblemView} = useAddProblemView();

	const problem = useMemo(
		() => convertProblem(extractedProblem, usersMap),
		[extractedProblem, usersMap]
	);
	const dispatch = useAppDispatch();
	const permissions = usePermissions(objectId, problem);

	const problemViewsArgs = {
		objectId,
		problemId,
		canSeeViews: permissions.canSeeViews
	};
	const {data: problemViews, isFetched} = useProblemViewsAsArray(problemViewsArgs);

	const isViewed = useProblemViewesByUser(problemViews, user.id);

	useEffect(() => {
		if (!!problemId && isFetched && !isViewed) {
			void addProblemView({objectId, problemId});
		}
	}, [problemId, isFetched, isViewed, objectId]);

	useAppHeader(
		{
			title: problem
				? `${t('problemPage.header.title')} №${problem.number || problem.localNumber}`
				: '',
			showBackBtn: true,
			mobileRightButtons: problem ? (
				<MobileRightButtons
					objectId={objectId}
					problem={problem}
					permissions={permissions}
				/>
			) : null
		},
		[problem]
	);

	useAsync(async () => {
		await dispatch(getProblem(objectId, problemId));
	});

	const isTablet = useIsTablet();
	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				problemId={problemId}
				problem={problem}
				permissions={permissions}
				settings={settings}
			/>
		);
	}
	if (isTablet) {
		return (
			<Tablet
				objectId={objectId}
				problemId={problemId}
				problem={problem}
				permissions={permissions}
				settings={settings}
			/>
		);
	}
	return (
		<Mobile
			objectId={objectId}
			problemId={problemId}
			problem={problem}
			permissions={permissions}
			settings={settings}
		/>
	);
};

export default ProblemPage;
