import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ReactNode, useCallback, useState} from 'react';
import {useExportWorkAcceptance} from '@src/core/hooks/mutations/workAcceptance/useExportWorkAcceptance';

const availableDestinations = [ExportTemplatesDestinationId.WORK_ACCEPTANCE];

export const useWorkAcceptanceExport = (
	objectId: string,
	checkLists?: string[],
	problems?: string[]
): [ReactNode, (id: string) => void] => {
	const [exportingId, setExportingId] = useState<string>();
	const [isOpen, setOpen] = useState(false);
	const {mutateAsync: exportWorkAcceptance} = useExportWorkAcceptance();

	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (exportingId) {
				await exportWorkAcceptance({
					templateId: template.id,
					createDocument,
					email,
					objectId,
					workAcceptanceId: exportingId,
					checkLists,
					problems
				});
			}
		},
		[objectId, exportingId, checkLists, problems]
	);

	const dialog = (
		<ExportDialog
			objectId={objectId}
			pageKey="workAcceptance"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
};
