import {IGetWorkingGroupsResponse} from '@src/api/backend/workingGroups';
import {IWorkingGroupsFiltersState} from '@src/store/modules/settings/pages/manage/workingGroups/slice';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

export const extractWorkingGroupsAsMap = (data: IGetWorkingGroupsResponse) => data.byId;

export const extractWorkingGroupsAllIds = (data: IGetWorkingGroupsResponse) => data.allIds;

export const extractWorkingGroupsAsArray = (data: IGetWorkingGroupsResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

export const extractFilteredWorkingGroupsAsArray = (
	data: IGetWorkingGroupsResponse,
	filters: IWorkingGroupsFiltersState
) => {
	const {objects, stages, name, types} = filters;
	const groups = extractWorkingGroupsAsArray(data);
	return groups.filter(group => {
		const isObjectsEquals =
			!objects?.length || group.objects?.some(object => objects.includes(object));
		const isStagesEquals =
			!stages?.length || group.stages?.some(stage => stages.includes(stage));
		const isTypesEquals = !types?.length || types.includes(group.type);
		const isNameEquals = !name || group.name.toLowerCase().includes(name.toLowerCase());

		return isObjectsEquals && isStagesEquals && isTypesEquals && isNameEquals;
	});
};

export const extractWorkingGroupsAsArrayByType = (
	data: IGetWorkingGroupsResponse,
	object?: IObject,
	stage?: ObjectStageIds,
	type?: WorkingGroupTypeId
) => {
	const workingGroups = extractWorkingGroupsAsArray(data);
	const companyId = object?.companyId;
	const groups: IWorkingGroup[] = [];
	for (const workingGroup of workingGroups) {
		const isTypesEquals = workingGroup.type === type;
		const isCompaniesEquals = !companyId || workingGroup.companyId === companyId;
		const isObjectsEquals =
			!object || (workingGroup.objects?.length && workingGroup.objects.includes(object.id));
		const isStagesEquals =
			!stage ||
			!workingGroup.stages ||
			(stage && workingGroup.stages && workingGroup?.stages.includes(stage));

		if (isTypesEquals && isCompaniesEquals && isObjectsEquals && isStagesEquals) {
			groups.push(workingGroup);
		}
	}

	return groups;
};
export const extractInspectorsGroupsAsArray = (
	data: IGetWorkingGroupsResponse,
	object?: IObject,
	stage?: ObjectStageIds
) => extractWorkingGroupsAsArrayByType(data, object, stage, WorkingGroupTypeId.INSPECTORS);

export const extractPerformersGroupsAsArray = (
	data: IGetWorkingGroupsResponse,
	object?: IObject,
	stage?: ObjectStageIds
) => extractWorkingGroupsAsArrayByType(data, object, stage, WorkingGroupTypeId.PERFORMERS);

export const extractAcceptorsGroupsAsArray = (
	data: IGetWorkingGroupsResponse,
	object?: IObject,
	stage?: ObjectStageIds
) => extractWorkingGroupsAsArrayByType(data, object, stage, WorkingGroupTypeId.ACCEPTORS);

export const extractWorkingGroupById = (
	data: IGetWorkingGroupsResponse,
	groupId: string | undefined
) => {
	if (groupId) {
		return data.byId[groupId];
	}
	return undefined;
};

export const extractWorkingGroupsByParentIdAsArray = (
	data: IGetWorkingGroupsResponse,
	parentId: string | undefined
) => {
	if (!parentId) {
		return [];
	}
	const allGroups = extractWorkingGroupsAsArray(data);
	return allGroups.filter(group => group.parentId === parentId);
};
