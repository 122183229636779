import getEmailsArray from '@src/utils/getEmailsArray';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IExportProblemsRequest, IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import getTimezone from '@tehzor/tools/utils/getTimezone';

/**
 * Отправляет запрос на экспорт нарушений
 */
export const makeProblemsExportRequest = async (
	templateId: string,
	objectId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	filters: Record<string, unknown> | undefined,
	sort: Record<string, boolean> | undefined,
	selected: string[] | undefined
): Promise<IDefferedExportResponse> => {
	const params: IExportProblemsRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId: objectId === 'all' ? undefined : objectId,
			filters: {
				...filters,
				selected
			},
			sort: convertSortParam(sort)
		}
	};

	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		'problems/export-problems',
		params
	);

	return response.data;
};
