import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';
import {ITableSettings} from '@src/interfaces/ITableSettings';
import {IChangeColumnVisiblePayload} from '@src/store/interfaces/IChangeColumnVisiblePayload';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {IChangeSearchByPayload} from '@src/store/interfaces/IChangeSearchBy';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangeTableSettingsPayload} from '@src/store/interfaces/IChangeTableSettingsPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IShowAllColumnPayload} from '@src/store/interfaces/IShowAllColumnPayload';
import {WarrantyClaimSearchField} from '@tehzor/tools/enums/WarrantyClaimSearchField';

export interface IGetWarrantyClaimsPayload extends IGetWarrantyClaimsResponse {
	objectId: string;
}

export interface IWarrantyClaimsFiltersState extends Record<string, unknown> {
	objects?: string[];
	spaces?: string[];
	number?: string;
	publicId?: string;
	statuses?: string[];
	spaceOwners?: string[];
	createdBy?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
	registrationDateFrom?: Date;
	registrationDateTo?: Date;
	searchString?: string;
	searchFieldId?: string;
}

export interface IWarrantyClaimsSortState {
	[column: string]: boolean;
}

export interface IWarrantyClaimsPageSettingsState {
	filters: IWarrantyClaimsFiltersState;
	sort: IWarrantyClaimsSortState;
	pageSize: number;
	offset: number;
	total: number;
	selectedRows: string[];
	isAllRowsSelected: boolean;
	tableSettings: ITableSettings;
	searchBy: string;
}

export type IWarrantyClaimsPagesSettingsState = Record<string, IWarrantyClaimsPageSettingsState>;

export const getInitialStateForPage = (objectId: string): IWarrantyClaimsPageSettingsState => ({
	filters: {
		objects: objectId !== 'all' ? [objectId] : undefined
	},
	sort: {
		createdAt: false
	},
	pageSize: 20,
	offset: 0,
	total: 0,
	selectedRows: [],
	isAllRowsSelected: false,
	tableSettings: {},
	searchBy: WarrantyClaimSearchField.CLAIM_NUMBER
});

const initialState: IWarrantyClaimsPagesSettingsState = {
	all: getInitialStateForPage('all')
};

const warrantyClaimsSlice = createSlice({
	name: 'warrantyClaims',
	initialState,
	reducers: {
		getRequest(state, action: PayloadAction<{objectId: string}>) {
			const {objectId} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
		},
		getSuccess(state, action: PayloadAction<IGetWarrantyClaimsPayload>) {
			const {objectId, offset, total} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			const page = state[objectId];
			page.offset = offset;
			page.total = total;
		},
		changeOffset(state, action: PayloadAction<{objectId?: string; offset: number}>) {
			const {objectId = 'all', offset} = action.payload;
			const page = state[objectId];
			if (page) {
				page.offset = offset;
			}
		},
		changeSelectedRows(
			state,
			action: PayloadAction<{objectId: string; selectedRows: string[]}>
		) {
			const {objectId = 'all', selectedRows} = action.payload;
			state[objectId].selectedRows = selectedRows;
		},
		toggleAllRowsSelected(
			state,
			action: PayloadAction<{objectId: string; isAllRowsSelected?: boolean}>
		) {
			const {objectId = 'all', isAllRowsSelected} = action.payload;
			const page = state[objectId];
			page.isAllRowsSelected =
				isAllRowsSelected !== undefined ? isAllRowsSelected : !page.isAllRowsSelected;
		},
		changeFilters(
			state,
			action: PayloadAction<IChangeFiltersPayload<IWarrantyClaimsFiltersState>>
		) {
			const {objectId = 'all', filters} = action.payload;
			if (objectId !== 'all') {
				filters.objects = [objectId];
			}
			state[objectId].filters = filters;
		},
		clearFilters(state, action: PayloadAction<IClearFiltersPayload>) {
			const {objectId = 'all'} = action.payload;
			const filters = {} as IWarrantyClaimsFiltersState;
			if (objectId !== 'all') {
				filters.objects = [objectId];
			}
			state[objectId].filters = filters;
		},
		changeSort(state, action: PayloadAction<IChangeSortPayload<IWarrantyClaimsSortState>>) {
			const {objectId = 'all', sort} = action.payload;
			state[objectId].sort = sort;
		},
		changePageSize(state, action: PayloadAction<IChangePageSizePayload>) {
			const {objectId = 'all', pageSize} = action.payload;
			state[objectId].pageSize = pageSize;
		},
		changeTableSettings(state, action: PayloadAction<IChangeTableSettingsPayload>) {
			const {objectId = 'all', tableSettings} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].tableSettings = tableSettings;
		},
		changeColumnVisible(state, action: PayloadAction<IChangeColumnVisiblePayload>) {
			const {objectId = 'all', columnKey} = action.payload;
			state[objectId].tableSettings[columnKey].isVisible =
				!state[objectId].tableSettings[columnKey].isVisible;
		},
		showAllColumns(state, action: PayloadAction<IShowAllColumnPayload>) {
			const {objectId = 'all'} = action.payload;
			for (const key in state[objectId].tableSettings) {
				if (state[objectId].tableSettings.hasOwnProperty(key)) {
					state[objectId].tableSettings[key].isVisible = true;
				}
			}
		},
		changeSearchBy(state, action: PayloadAction<IChangeSearchByPayload>) {
			const {objectId, searchBy} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].searchBy = searchBy;
		}
	}
});

export const {
	getRequest,
	getSuccess,
	changeOffset,
	changeSelectedRows,
	toggleAllRowsSelected,
	changeFilters,
	clearFilters,
	changeSort,
	changePageSize,
	changeTableSettings,
	changeColumnVisible,
	showAllColumns,
	changeSearchBy
} = warrantyClaimsSlice.actions;

export default warrantyClaimsSlice.reducer;
