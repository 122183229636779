import {useMemo} from 'react';
import './InspectionPage.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';

import {useIsDesktop, useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import Desktop from './components/Desktop';
import Tablet from './components/Tablet';
import Mobile from './components/Mobile';
import MobileRightButtons from './components/actions/RightButtons.mobile';
import {convertLocalAttachments} from '@src/utils/convertLocalAttachments';
import {ISavingInspectionQuery} from '@src/core/hooks/mutations/inspection/useAddInspection';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {useTranslation} from 'react-i18next';
import {useInspectionSettings} from '@src/core/hooks/settings/useInspectionSettings';
import {useInspection} from '@src/core/hooks/queries/inspections/hooks';

const InspectionPage = () => {
	const {objectId, inspectionId} = useStrictParams<{objectId: string; inspectionId: string}>();
	const {t} = useTranslation();

	useScrollRestoration();
	const settings = useInspectionSettings(objectId);

	const {data: extractedInspection} = useInspection(inspectionId, objectId);
	const inspection = useMemo(
		() =>
			extractedInspection?.localNumber
				? {
						...extractedInspection,
						attachments: convertLocalAttachments<ISavingInspectionQuery>(
							extractedInspection.id,
							inspectionsQueryKeys
						)
				  }
				: extractedInspection,
		[extractedInspection]
	);

	useAppHeader(
		{
			title: inspection
				? `${t('inspectionPage.header')} №${inspection.number || inspection.localNumber}`
				: '',
			showBackBtn: true,
			mobileRightButtons: inspection ? (
				<MobileRightButtons
					objectId={objectId}
					inspection={inspection}
				/>
			) : null
		},
		[inspection]
	);
	const isTablet = useIsTablet();
	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				inspectionId={inspectionId}
				inspection={inspection}
				settings={settings}
			/>
		);
	}
	if (isTablet) {
		return (
			<Tablet
				objectId={objectId}
				inspection={inspection}
				settings={settings}
			/>
		);
	}

	return (
		<Mobile
			objectId={objectId}
			inspection={inspection}
			settings={settings}
		/>
	);
};

export default InspectionPage;
