import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {
	IDefferedExportResponse,
	IExportInternalAcceptanceRequest
} from '@tehzor/tools/interfaces/export';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт внутренней приёмки
 */
export const makeInternalAcceptanceExportRequest = async (
	templateId: string,
	objectId: string,
	internalAcceptanceId: string,
	problems?: string[],
	inspections?: string[],
	createDocument?: boolean,
	email?: string
): Promise<IDefferedExportResponse> => {
	const params: IExportInternalAcceptanceRequest = {
		templateId,
		timezone: getTimezone(),
		createDocument,
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId,
			problemsIds: problems,
			inspectionsIds: inspections
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		`internal-acceptances/${internalAcceptanceId}/export`,
		params
	);
	return response.data;
};
