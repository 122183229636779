import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IDefferedExportResponse, IExportProblemReplyRequest} from '@tehzor/tools/interfaces/export';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт ответа на нарушение
 *
 * @param templateId id шаблона экспорта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param objectId id объекта
 * @param commentId id комментария
 */
export const makeProblemReplyExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	commentId: string
): Promise<IDefferedExportResponse> => {
	const params: IExportProblemReplyRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId
		}
	};
	const response = await httpRequests.exportWithToken.get<IDefferedExportResponse>(
		`problem-replies/${commentId}/export`,
		{
			params
		}
	);
	return response.data;
};
