import {Dispatch, memo} from 'react';
import './EditableContract.less';
import {
	IEditableContractAction,
	IEditableContractState
} from '@src/core/hooks/states/useEditableContractState';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {NameBlock} from './blocks/NameBlock';
import {CompanyBlock} from './blocks/CompanyBlock';
import {ContractorBlock} from './blocks/ContractorBlock';
import {MainBlock} from './blocks/MainBlock';
import {DatesBlock} from './blocks/DatesBlock';
import {PriceBlock} from './blocks/PriceBlock';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import Attachments from '../editableFields/Attachments';

interface IEditableContractProps {
	contractId?: string;
	editingDispatch: Dispatch<IEditableContractAction>;
	editingState: IEditableContractState;

	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
}

export const EditableContract = memo(
	({
		contractId,
		editingDispatch,
		editingState,
		uploadingFilesState,
		uploadingFilesDispatch
	}: IEditableContractProps) => {
		const isLargeTablet = useIsLargeTablet();

		const handleChange = (
			value: string | number | string[] | null | undefined,
			field: keyof IEditableContractState
		) => {
			editingDispatch({type: 'update', value, field});
		};

		const handleError = (field: keyof IEditableContractState) => {
			editingDispatch({type: 'update-error', field});
		};

		return (
			<div className="editable-contract">
				<div className="editable-contract__blocks">
					<NameBlock
						editingState={editingState}
						onChange={handleChange}
						onError={handleError}
					/>

					<CompanyBlock
						editingState={editingState}
						isMobile={!isLargeTablet}
						onChange={handleChange}
						onError={handleError}
					/>

					<ContractorBlock
						editingState={editingState}
						isMobile={!isLargeTablet}
						onChange={handleChange}
						onError={handleError}
					/>

					<MainBlock
						editingState={editingState}
						isMobile={!isLargeTablet}
						onChange={handleChange}
						onError={handleError}
					/>

					<DatesBlock
						editingState={editingState}
						onChange={handleChange}
						onError={handleError}
					/>

					<PriceBlock
						editingState={editingState}
						onChange={handleChange}
						onError={handleError}
					/>
				</div>

				<div>
					<Attachments
						entityId={contractId}
						label="Прикрепить файл"
						attachments={editingState.attachments || []}
						uploadingFiles={uploadingFilesState.value}
						editingDispatch={editingDispatch}
						uploadingFilesDispatch={uploadingFilesDispatch}
						hasError={uploadingFilesState.value.length > 1}
						showAttachBtn
						saveToS3
						errorText="недопустимое расширение файла"
						imagesTitle="Вложение"
					/>
				</div>
			</div>
		);
	}
);
