import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {ExportTaskStatus} from '@tehzor/tools/interfaces/export/ExportTaskStatus';
import {IDefferedExportTasksState} from './interfaces';
import {IExportTasksFinished} from '@tehzor/tools/interfaces/export/IExportTasksFinished';
import {IExportTasksFailed} from '@tehzor/tools/interfaces/export/IExportTasksFailed';

/**
 * Возвращает все отложенные задачи
 */
export const extractDefferedExportTasks = createSelector(
	(state: IState) => state.defferedTasks.export,
	data => data
);

/**
 * Возвращает все отложенные задачи в состоянии pending
 */
export const extractPendingDefferedExportTasks = createSelector(
	(state: IState) => state.defferedTasks.export,
	defferedExportTasks =>
		Object.keys(defferedExportTasks).reduce<IDefferedExportTasksState>((acc, taskId) => {
			if (defferedExportTasks[taskId].status === ExportTaskStatus.PENDING) {
				acc[taskId] = defferedExportTasks[taskId];
			}
			return acc;
		}, {})
);
/**
 * Возвращает все отложенные задачи в состоянии finished
 */
export const extractFinishedDefferedExportTasks = createSelector(
	(state: IState) => state.defferedTasks.export,
	defferedExportTasks =>
		Object.keys(defferedExportTasks).reduce<IDefferedExportTasksState>((acc, taskId) => {
			if (defferedExportTasks[taskId].status === ExportTaskStatus.FINISHED) {
				acc[taskId] = defferedExportTasks[taskId];
			}
			return acc;
		}, {}) as IDefferedExportTasksState<IExportTasksFinished>
);

/**
 * Возвращает все отложенные задачи в состоянии failed
 */
export const extractFailedDefferedExportTasks = createSelector(
	(state: IState) => state.defferedTasks.export,
	defferedExportTasks =>
		Object.keys(defferedExportTasks).reduce<IDefferedExportTasksState>((acc, taskId) => {
			if (defferedExportTasks[taskId].status === ExportTaskStatus.FAILED) {
				acc[taskId] = defferedExportTasks[taskId];
			}
			return acc;
		}, {}) as IDefferedExportTasksState<IExportTasksFailed>
);
/**
 * Возвращает статус одной задачи экспорта
 */
export const extractDefferedExportTaskStatusById = createSelector(
	(state: IState) => state.defferedTasks.export,
	(state: IState, taskId: string) => taskId,
	(data, taskId) => data[taskId]
);

/**
 * Возвращает true если хотя бы одна задача находится в статусе pending
 */
export const hasPendingDefferedExportTask = createSelector(
	(state: IState) => state.defferedTasks.export,
	data =>
		Object.values(data).some(defferedTask => defferedTask.status === ExportTaskStatus.PENDING)
);
