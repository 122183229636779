import {useCallback} from 'react';
import WarrantyClaimsPage from './WarrantyClaimsPage';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	type IWarrantyClaimsFiltersState,
	changeFilters,
	changeOffset,
	changeSearchBy,
	clearFilters
} from '@src/store/modules/settings/pages/warrantyClaims/slices';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import {WarrantyClaimSearchField} from '@tehzor/tools/enums/WarrantyClaimSearchField';

const AllWarrantyClaimsPageWrap = (props: object) => {
	const dispatch = useAppDispatch();

	const {filters} = useAppSelector(s => extractWarrantyClaimsPageSettings(s, 'all'));

	const applyFilters = useCallback((value: IWarrantyClaimsFiltersState) => {
		dispatch(changeFilters({objectId: 'all', filters: value}));
		dispatch(changeOffset({objectId: 'all', offset: 0}));
	}, []);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: 'all'}));
		dispatch(
			changeSearchBy({objectId: 'all', searchBy: WarrantyClaimSearchField.CLAIM_NUMBER})
		);
	}, []);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<WarrantyClaimsPage
				key="all-warranty-claims"
				className="page-cont_extra-bottom-margin"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};

export default AllWarrantyClaimsPageWrap;
