import {memo} from 'react';
import {CellProps} from 'react-table';
import {StatusSelect} from '@tehzor/ui-components';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useWarrantyClaimsStatuses} from '@src/core/hooks/queries/warrantyClaimsStatuses/hooks';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IPreparedWarrantyClaimEntity} from '../../interfaces/IPreparedWarrantyClaimEntity';
import {useEditWarrantyClaimStatus} from '@src/core/hooks/mutations/warrantyClaims/useEditStatusWarrantyClaim';

export const StatusCell = memo(({row}: CellProps<IPreparedWarrantyClaimEntity>) => {
	const claim = row.original;
	const objectId = claim.data.object?.id ?? 'all';
	const {mutateAsync: editWarrantyClaimStatus} = useEditWarrantyClaimStatus();

	const permissions = useWarrantyClaimsPermissions(objectId);

	const {data: statuses} = useWarrantyClaimsStatuses();
	const translatedStatuses = useTranslatedDictionary(
		dictionaryKeys.warrantyClaimStatuses,
		statuses
	);

	const [, handleChange] = useAsyncFn(
		async (v: WarrantyClaimStatusId) => {
			if (claim) {
				await editWarrantyClaimStatus({objectId, claimId: claim.id, statusId: v});
			}
		},
		[objectId, claim]
	);

	return (
		<ClickPreventWrap className="warranty-claims-table__status-cell">
			<StatusSelect
				statuses={translatedStatuses}
				disabled={!permissions.canEditStatus}
				value={claim?.data.statusId}
				onChange={handleChange}
				style={{maxWidth: '100%'}}
			/>
		</ClickPreventWrap>
	);
});
