import {useMutation} from '@tanstack/react-query';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {makeInternalAcceptanceExportRequest} from '@src/api/backend/internalAcceptance';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

interface IExportInternalAcceptanceParams {
	templateId: string;
	objectId: string;
	internalAcceptanceId: string;
	problems?: string[];
	inspections?: string[];
	createDocument?: boolean;
	email?: string;
}

export const useExportInternalAcceptance = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation({
		mutationKey: internalAcceptancesQueryKeys.exportOne(),
		mutationFn: (params: IExportInternalAcceptanceParams) => {
			const {
				templateId,
				objectId,
				internalAcceptanceId,
				problems,
				inspections,
				createDocument,
				email
			} = params;
			return makeInternalAcceptanceExportRequest(
				templateId,
				objectId,
				internalAcceptanceId,
				problems,
				inspections,
				createDocument,
				email
			);
		},
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
