import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';

export type IAddWarrantyClaimResponse = IWarrantyClaim;

/**
 * Добавляет гарантийное обращение
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param fields данные
 */
export const makeWarrantyClaimAddRequest = async (
	objectId: string,
	links: IWarrantyClaim['links'] | undefined,
	fields: ISavingWarrantyClaim,
	localId?: string
) => {
	const response = await httpRequests.withToken.post<IAddWarrantyClaimResponse>(
		'warranty-claims',
		{
			objectId,
			links,
			localId,
			...fields
		}
	);

	return response.data;
};
