import {QueryClient, QueryFunctionContext} from '@tanstack/react-query';
import {defferedFileExportQueryKeys, IDefferedTasksQueryKey} from '../keys';
import {getDefferedTasks} from '@src/api/backend/defferedFileExport/getDefferedTasks';

export const defferedFileExportQueryConfig = (queryClient: QueryClient) => {
	queryClient.setQueryDefaults(defferedFileExportQueryKeys.tasks(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IDefferedTasksQueryKey>) => {
			const [, , userId] = queryKey;
			return getDefferedTasks(userId);
		},
		meta: {
			error: 'при загрузке списка отоложенных задач'
		},
		staleTime: 1000 * 60 * 60 * 24, // 1 День
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
