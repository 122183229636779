import {useTranslation} from 'react-i18next';
import {useUpdateEffect} from 'react-use';
import {addSuccessToast, closeToast} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPendingDefferedExportTask} from '@src/store/modules/defferedTasks/export/selectors';

export const useExportPending = () => {
	const {t} = useTranslation();
	const toastId = 'pendingExportTask';
	const hasPendingTasks = useAppSelector(hasPendingDefferedExportTask);

	useUpdateEffect(() => {
		if (hasPendingTasks) {
			addSuccessToast({title: t('toast.processing.startExport.title'), options: {toastId}});
		} else {
			closeToast({id: toastId});
		}
	}, [hasPendingTasks]);
};
