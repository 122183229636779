import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {useDeleteInspection} from '@src/core/hooks/mutations/inspection/useDeleteInspection';
import {useCallback} from 'react';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IStructureEntity[];
}

export const SelectedEntitiesDeletion = ({selectedEntities}: ISelectedEntitiesDeletionProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const deleteInspection = useDeleteInspection();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.selectedEntities.deleteTitle'),
		message: t('useConfirmDialog.selectedEntities.deleteMessage')
	});

	const handleClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			const subRowsNumber = 0;
			const objectsIds: string[] = [];
			for (const {type, data} of selectedEntities) {
				switch (type) {
					case 'problem':
						if (!subRowsNumber) {
							await dispatch(deleteProblem(data.objectId, data.id));
							objectsIds.push(data.objectId);
						}
						break;
					case 'inspection':
						if (!subRowsNumber) {
							await deleteInspection({
								objectId: data.objectId,
								inspectionId: data.id
							});
						}
						break;
				}
			}
			if (objectsIds.length > 0) {
				await queryClient.refetchQueries({
					queryKey: [...objectsQueryKeys.stats(), objectsIds]
				});
			}
		}
	}, [selectedEntities, getDeleteConfirmation, dispatch, deleteInspection]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete" />
			</IconButton>

			{deleteDialog}
		</>
	);
};
