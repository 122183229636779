import {combineReducers} from 'redux';
import app from './app';
import {auth} from './auth';
import {offlineMode} from './offlineMode/reducers';
import {entities} from './entities';
import {settings} from './settings';
import pages from './pages';
import localEntities from '../../reducers/local-entities';
import notifications from '../../reducers/notifications';
import statistics from './statistics';
import ui from './ui';
import {defferedTasks} from './defferedTasks';

export const rootReducer = combineReducers({
	app,
	defferedTasks,
	auth,
	offlineMode,
	entities,
	settings,
	pages,
	localEntities,
	notifications,
	statistics,
	ui
});

export type IState = ReturnType<typeof rootReducer>;
