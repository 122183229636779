import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import useAppSelector from '../../useAppSelector';
import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';
import {IWarrantyClaimsFiltersState} from '@src/store/modules/settings/pages/warrantyClaims/slices';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import {extractLocalSpaceWarrantyClaims, extractWarrantyClaimsAsArray} from './selectors';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {useCallback, useMemo} from 'react';
import {useExtractLatestWarrantyClaimsAsArray} from './latest/hooks';
import {extractNetworkStatus} from '@src/store/modules/offlineMode/selectors';
import {IWarrantyClaimsProblemsDataRequest} from '@src/interfaces/warrantyClaimsStatsData';
import {IGetWarrantyClaimsProblemsResponse} from '@src/api/backend/warrantyClaims/getStatsData';

export const useWarrantyClaims = <T = IGetWarrantyClaimsResponse>(
	objectId = 'all',
	state?: IWarrantyClaimsFiltersState,
	select?: (data: IGetWarrantyClaimsResponse) => T
) => {
	const {filters, sort, offset, pageSize} = useAppSelector(s =>
		extractWarrantyClaimsPageSettings(s, objectId)
	);

	return useQuery<IGetWarrantyClaimsResponse, IError, T>({
		queryKey: warrantyClaimsQueryKeys.list(objectId, state || filters, sort, offset, pageSize),
		select
	});
};

export const useWarrantyClaimsAsArray = (objectId?: string, state?: IWarrantyClaimsFiltersState) =>
	useWarrantyClaims(objectId, state, extractWarrantyClaimsAsArray);

export const useLocalWarrantyClaims = <T = IListWarrantyClaim[]>(
	objectId = 'all',
	select?: (data: IListWarrantyClaim[]) => T
) => {
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	return useQuery<IListWarrantyClaim[], IError, T>({
		queryKey: [...warrantyClaimsQueryKeys.localList(), objectId],
		staleTime: 0,
		gcTime: Infinity,
		networkMode: 'offlineFirst',
		enabled: isOfflineModeAvailable,
		select
	});
};

export const useExtractLocalSpaceWarrantyClaims = (objectId: string, spaceId: string) => {
	const selector = useCallback(
		(data: IListWarrantyClaim[]) => extractLocalSpaceWarrantyClaims(data, spaceId),
		[spaceId]
	);
	return useLocalWarrantyClaims(objectId, selector);
};

export const useMergedSpaceLocalAndLatestWarrantyClaims = (objectId: string, spaceId: string) => {
	const offlineMode = useAppSelector(extractOfflineModeAvailability);
	const isOnline = useAppSelector(extractNetworkStatus);
	const appIsOffline = offlineMode && !isOnline;
	const {data: latestWarrantyClaim} = useExtractLatestWarrantyClaimsAsArray(objectId, spaceId);
	const {data: localWarrantyClaims} = useExtractLocalSpaceWarrantyClaims(objectId, spaceId);

	return useMemo(
		() =>
			appIsOffline
				? [...(latestWarrantyClaim ?? []), ...(localWarrantyClaims ?? [])]
				: localWarrantyClaims,
		[latestWarrantyClaim, localWarrantyClaims, appIsOffline]
	);
};

export const useWarrantyClaimsStatsData = (data?: IGetWarrantyClaimsResponse) => {
	const warrantyData = useMemo<IWarrantyClaimsProblemsDataRequest[]>(() => {
		if (!data) return [];
		return data.allIds.map(id => ({
			objectId: data.byId[id].objectId,
			warrantyClaimId: data.byId[id].id
		}));
	}, [data]);

	return useQuery<IGetWarrantyClaimsProblemsResponse, IError, IGetWarrantyClaimsProblemsResponse>(
		{
			queryKey: warrantyClaimsQueryKeys.warrantyProblemsStats(warrantyData),
			enabled: !!warrantyData.length
		}
	);
};
