import {IEditInspectionResponse, makeInspectionEditRequest} from '@src/api/backend/inspection';
import {IAddInspectionResponse, makeInspectionAddRequest} from '@src/api/backend/inspection/add';
import {useQueryClient} from '@tanstack/react-query';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {addTempFiles} from '../../utils/addTempFiles';
import {inspectionsQueryKeys} from '../keys';
import {invalidateLinkedInspectionsQueries} from '@src/cache/actions/inspection/invalidateLinkedInspectionsQueries';
import {IInspectionsPagesSettingsState} from '@src/store/modules/settings/pages/inspections/interfaces';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ADD_SUCCESS, EDIT_SUCCESS} from '@src/store/modules/entities/inspection/constants';

export interface IAddInspectionParams {
	objectId: string;
	links: IInspection['links'] | undefined;
	stage: ObjectStageIds | undefined;
	processId: ProcessIds;
	fields: ISavingInspection;
	key: string;
	currentQueryFilter?: IInspectionsPagesSettingsState;
}

export interface IEditInspectionParams {
	inspectionId: string;
	objectId: string;
	fields: ISavingInspection;
}

/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useInspectionsMutationDefaults = () => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	queryClient.setMutationDefaults(inspectionsQueryKeys.add(), {
		mutationFn: async (params: IAddInspectionParams) => {
			const {objectId, links, stage, processId, fields, key} = params;
			return makeInspectionAddRequest(
				objectId,
				links,
				{
					...fields,
					newAttachments: await addTempFiles(fields.newAttachments)
				},
				processId,
				key,
				stage
			);
		},
		onSuccess: (data: IAddInspectionResponse, {key, objectId, links}: IAddInspectionParams) => {
			queryClient.removeQueries({queryKey: inspectionsQueryKeys.savingData(key)});

			void invalidateLinkedInspectionsQueries(objectId, links);
			void queryClient.refetchQueries({
				queryKey: inspectionsQueryKeys.localList()
			});
			void queryClient.invalidateQueries({
				queryKey: [...inspectionsQueryKeys.list(), objectId]
			});
			dispatch({type: ADD_SUCCESS, payload: data});
			addSuccessToast({title: t('toast.addInspection.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addInspection.error.title')});
		},
		retry: false
	});

	queryClient.setMutationDefaults(inspectionsQueryKeys.edit(), {
		mutationFn: async ({inspectionId, objectId, fields}: IEditInspectionParams) => {
			await queryClient.cancelQueries({
				queryKey: inspectionsQueryKeys.detail(inspectionId)
			});
			return makeInspectionEditRequest(objectId, inspectionId, {
				...fields,
				newAttachments: await addTempFiles(fields.newAttachments)
			});
		},
		onError: () => {
			addErrorToast({title: t('toast.editInspection.error.title')});
		},
		onSuccess: async (
			data: IEditInspectionResponse,
			{inspectionId, objectId}: IEditInspectionParams
		) => {
			dispatch({type: EDIT_SUCCESS, payload: data});
			await invalidateLinkedInspectionsQueries(objectId);
			await queryClient.invalidateQueries({
				queryKey: inspectionsQueryKeys.detail(inspectionId)
			});
			await queryClient.invalidateQueries({
				queryKey: [...inspectionsQueryKeys.list(), objectId]
			});
			await queryClient.invalidateQueries({
				queryKey: delegationHistoryQueryKeys.histories({
					objectId,
					inspectionId,
					workingGroupType: WorkingGroupTypeId.PERFORMERS
				})
			});
		}
	});
};
