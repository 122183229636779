import {useCallback, useState, ReactNode} from 'react';
import {EditWarrantyClaimDialog} from '@src/components/EditWarrantyClaimDialog';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useAsyncFn} from 'react-use';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {useEditWarrantyClaim} from '@src/core/hooks/mutations/warrantyClaims/useEditWarrantyClaim';

export const useEditWarrantyClaimDialog = (
	warrantyClaim: IWarrantyClaim
): [ReactNode, () => void] => {
	const [isOpen, setOpen] = useState(false);
	const {mutateAsync: editWarrantyClaim} = useEditWarrantyClaim();
	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const [{loading}, handleWarrantyClaimChange] = useAsyncFn(
		async (value: ISavingWarrantyClaim) => {
			if (warrantyClaim) {
				await editWarrantyClaim({
					objectId: warrantyClaim.objectId,
					claimId: warrantyClaim.id,
					data: value
				});
			}
		},
		[warrantyClaim]
	);

	const dialog = (
		<EditWarrantyClaimDialog
			isOpen={isOpen}
			onClose={close}
			warrantyClaim={warrantyClaim}
			loading={loading}
			onChange={handleWarrantyClaimChange}
		/>
	);

	return [dialog, open];
};
