import {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useInspectionBreadcrumbs} from '@src/core/hooks/breadcrumbs/useInspectionBreadcrumbs';
import DesktopInspectionExportBtn from './actions/InspectionExportBtn.desktop';
import {usePermissions} from '../hooks/usePermissions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useInspection} from '@src/core/hooks/queries/inspections/hooks';

interface IPageBreadcrumbsProps {
	objectId: string;
	inspectionId: string;
}

const PageBreadcrumbs = ({objectId, inspectionId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useInspectionBreadcrumbs(objectId, inspectionId);
	const isDesktop = useIsDesktop();
	const {data: inspection} = useInspection(inspectionId, objectId);
	const permissions = usePermissions(objectId, inspection);
	return (
		<AppBreadcrumbs
			className="inspection-page__breadcrumbs"
			items={breadcrumbs}
		>
			{permissions.canExport && isDesktop ? (
				<DesktopInspectionExportBtn
					objectId={objectId}
					inspectionId={inspectionId}
				/>
			) : null}
		</AppBreadcrumbs>
	);
};

export default memo(PageBreadcrumbs);
