import {useCallback, useState} from 'react';
import './EditableAttachmentsDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useEditableWarrantyClaimAttachments} from '../EditableWarrantyClaimAttachments/hooks/useEditableWarrantyClaimAttachments';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useTranslation} from 'react-i18next';
import {useEditWarrantyClaim} from '@src/core/hooks/mutations/warrantyClaims/useEditWarrantyClaim';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IEditableWarrantyClaimAttachmentsDialogProps {
	objectId: string;
	warrantyClaimId: string;
	warrantyClaim: IWarrantyClaim;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Окно редактирования вложений обращения
 */
const EditableWarrantyClaimAttachmentsDialog = ({
	objectId,
	warrantyClaimId,
	warrantyClaim,
	isOpen,
	onClose
}: IEditableWarrantyClaimAttachmentsDialogProps) => {
	const {t} = useTranslation();
	const {mutateAsync: editWarrantyClaim} = useEditWarrantyClaim();
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableWarrantyClaimAttachments(
		objectId,
		warrantyClaim,
		saving
	);
	const save = useCallback(async () => {
		setSaving(true);
		if (warrantyClaimId) {
			const savingData = await getSavingData();
			if (savingData) {
				if (!networkStatus) {
					// Локальное сохранение
					// const savingData = convertToSave(editingState, problem, true);
					// savingData.newAttachments = convertToLocalSave(files);
					// TODO Локальное сохранение
				} else {
					try {
						await editWarrantyClaim({
							objectId,
							claimId: warrantyClaimId,
							data: savingData
						});
						onClose();
					} catch (error) {
						console.log(error);
						// TODO Локальное сохранение
					}
				}
			}
		}
		setSaving(false);
	}, [networkStatus, objectId, warrantyClaimId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-attachments-dialog"
			isOpen={isOpen}
			title={t('editingAttachmentDialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('actionButtons.button.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('actionButtons.button.save')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};

export default EditableWarrantyClaimAttachmentsDialog;
