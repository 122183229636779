import {useMutation, useQueryClient} from '@tanstack/react-query';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';
import {
	makeWarrantyClaimMoveRequest,
	IMoveWarrantyClaimResponse
} from '@src/api/backend/warrantyClaim';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';

export interface IMoveWarrantyClaimPayload extends ILinkedWarrantyClaim {
	objectId: string;
	claimId: string;
	toObjectId: string;
	toSpaceId: string;
}

export const useMoveWarrantyClaim = () => {
	const queryClient = useQueryClient();

	return useMutation<IMoveWarrantyClaimResponse, Error, IMoveWarrantyClaimPayload>({
		mutationKey: warrantyClaimsQueryKeys.moveOne(),
		mutationFn: params =>
			makeWarrantyClaimMoveRequest(
				params.objectId,
				params.claimId,
				params.toObjectId,
				params.toSpaceId
			),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.details()});
			void queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.lists()});
		},
		onError: () => {
			addErrorToast({title: t('toast.moveWarrantyClaim.error.title')});
		}
	});
};
