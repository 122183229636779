import {IPermissions} from '../hooks/usePermissions';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';

import Menu from './Menu';

interface IMobileRightButtonsProps {
	objectId: string;
	problem: IPreparedProblem;
	permissions: IPermissions;
}
export const MobileRightButtons = ({objectId, problem, permissions}: IMobileRightButtonsProps) => {
	return (
		<div className="problem-page__mobile-right-buttons">
			<Menu
				objectId={objectId}
				problem={problem}
				permissions={permissions}
			/>
		</div>
	);
};
