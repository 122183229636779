import {makeInspectionPerformersEditRequest} from '@src/api/backend/inspection';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IEditInspectionPerfomersParams {
	objectId: string;
	inspectionId: string;
	respUsers?: string[];
	activeGroup?: string;
}
export const useEditInspectionPerformers = () => {
	const {t} = useTranslation();
	return useMutation({
		mutationKey: inspectionsQueryKeys.editPerfomers(),
		mutationFn: (params: IEditInspectionPerfomersParams) => {
			const {objectId, inspectionId, respUsers, activeGroup} = params;
			return makeInspectionPerformersEditRequest(
				objectId,
				inspectionId,
				respUsers,
				activeGroup
			);
		},
		onError: () => addErrorToast({title: t('toast.editInspectionPerformers.error.title')}),
		onSuccess: (_, variables) => {
			const {objectId, inspectionId} = variables;
			void queryClient.invalidateQueries({
				queryKey: [...inspectionsQueryKeys.list(), objectId]
			});
			void queryClient.invalidateQueries({
				queryKey: [...inspectionsQueryKeys.detail(inspectionId), objectId]
			});
			void queryClient.invalidateQueries({
				queryKey: delegationHistoryQueryKeys.histories({
					objectId,
					inspectionId,
					workingGroupType: WorkingGroupTypeId.PERFORMERS
				})
			});
		}
	});
};
