import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IDefferedExportTask} from '@tehzor/tools/interfaces/export/IDefferedExportTask';

export type IDefferedTasksGetByInitiatorResponse = IDefferedExportTask[];

/**
 * Возвращает список отложенных задач экспорта
 */
export const getDefferedTasks = async (userId: string) => {
	const response = await httpRequests.withToken.get<IDefferedTasksGetByInitiatorResponse>(
		`deferred-file-export/tasks/${userId}`
	);
	return response.data;
};
