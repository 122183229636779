/* eslint-disable class-methods-use-this */
/* eslint-disable no-shadow */
import BaseSocketConnector from '@tehzor/tools/api/BaseSocketConnector';
import {ExportTaskEvents} from '@tehzor/tools/interfaces/export/ExportTaskEvents';
import {IExportTasksFailed} from '@tehzor/tools/interfaces/export/IExportTasksFailed';
import {IExportTasksFinished} from '@tehzor/tools/interfaces/export/IExportTasksFinished';

const singleton: unique symbol = Symbol('singleton');
const singletonEnforcer: unique symbol = Symbol('singletonEnforcer');

/**
 * Singleton класс для взаимодействия с backend'ом
 */
class SocketConnector extends BaseSocketConnector {
	private static [singleton]: SocketConnector;

	constructor(enforcer: symbol) {
		super();
		if (enforcer !== singletonEnforcer) {
			throw new Error("Cannot construct 'SocketConnector' class");
		}
	}

	/**
	 * Возвращает единственный экземпляр класса SocketConnector
	 *
	 * @returns {SocketConnector}
	 */
	static get instance(): SocketConnector {
		if (!this[singleton]) {
			this[singleton] = new SocketConnector(singletonEnforcer);
		}
		return this[singleton];
	}

	// Метод для подписки на сообщения об экспорте
	subscribeOnExportEvents(
		onSuccessEvent: (event: IExportTasksFinished) => void,
		onErrorEvent: (event: IExportTasksFailed) => void
	) {
		this._subscribe(ExportTaskEvents.EXPORT_TASKS_FINISHED, onSuccessEvent, true);
		this._subscribe(ExportTaskEvents.EXPORT_TASKS_FAILED, onErrorEvent, true);
	}

	// Метод для отписки от сообщений об экспорте
	unsubscribeOnExportEvents() {
		this._unsubscribe(ExportTaskEvents.EXPORT_TASKS_FINISHED);
		this._unsubscribe(ExportTaskEvents.EXPORT_TASKS_FAILED);
	}
}

export default SocketConnector;
