import {useEffect} from 'react';
import './App.less';
import AppHeader from '../AppHeader';
import {AppSidebar} from '../AppSidebar';
import AppBottomMenu from '../AppBottomMenu';
import AppNotifications from '../AppNotifications';
import AppContent from '../AppContent';
import {AppUpdateDialog} from '../AppUpdateDialog';
import {ResourcesLoader} from '../ResourcesLoader';
import ScrollRestoration from '@tehzor/tools/components/ScrollRestoration';
import {BeforeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {PageLeavingBlock, Toasts} from '@tehzor/ui-components';
import {useOfflineMode} from '@src/core/offlineMode/hooks/useOfflineMode';
import {isMobileDevice} from '@src/utils/isMobileDevice';
import {OfflineModeDialog} from '@src/components/OfflineModeDialog';
import {OnlyActivated} from '@src/components/OnlyActivated';
import {useCacheDefaults} from '@src/api/cache/default';
import history from '@src/core/history';
import {onlineManager} from '@tanstack/react-query';
import {EmailNotificationsCheck} from '@src/components/EmailNotificationsCheck/EmailNotificationsCheck';
import '@tehzor/ui-components/src/components/Toasts/toastify.less';
import {useIsBeforeLargeMobile} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {DefferedFileExport} from '../DefferedFileExport/DefferedFileExport';

/**
 * Корневой компонент приложения
 */
export const App = () => {
	useCacheDefaults();

	useEffect(() => {
		// Добавление корневого класса в зависимости от устройства
		document.body.classList.add(isMobileDevice() ? 'mobile' : 'desktop');
	}, []);

	useEffect(() => {
		if (
			typeof navigator.onLine !== 'undefined' &&
			navigator.onLine !== onlineManager.isOnline()
		) {
			onlineManager.setOnline(navigator.onLine);
		}
	}, []);

	useOfflineMode();

	const isLargeMobile = useIsBeforeLargeMobile();

	return (
		// Оборачивание в AppHeader необходимо для доступности контекста шапки в компонентах страницы
		<>
			<AppHeader>
				<div
					id="scroll-container"
					className="app__wrap"
				>
					<ScrollRestoration
						history={history}
						scrollContainerId="scroll-container"
					>
						<PageLeavingBlock history={history}>
							<ResourcesLoader>
								<AppContent />
							</ResourcesLoader>
						</PageLeavingBlock>
					</ScrollRestoration>

					<BeforeTablet>
						<AppBottomMenu />
					</BeforeTablet>

					<AppNotifications />
				</div>
			</AppHeader>
			<Toasts isDesktop={!isLargeMobile} />
			<AppUpdateDialog />

			<OnlyActivated>
				<AppSidebar />
				<OfflineModeDialog />
				<EmailNotificationsCheck />
				<DefferedFileExport />
			</OnlyActivated>
		</>
	);
};
