import {Dialog, InlineButton, LinkButton, Plate} from '@tehzor/ui-components';
import {convertEntityName} from '../../utils/convertEntityName';
import {IDocument, IDocumentLink} from '@tehzor/tools/interfaces/documents/IDocument';
import {useCallback, useMemo, useState} from 'react';

const LINKS_COUNT = 3;
const linkIcon = <i className="tz-external-link-16" />;

export interface IDocumentProps {
	document: IDocument;
}

export const DocumentLinks = ({document}: IDocumentProps) => {
	const [isModalOpen, setModalOpen] = useState(false);

	const openModal = useCallback(() => setModalOpen(true), []);
	const closeModal = useCallback(() => setModalOpen(false), []);

	const goToLink = (link: IDocumentLink) => {
		// TODO TZ-3459 (Раскомментировать, когда в объект документа будет добавлен objectId для правильного формирования ссылок)
		// const createdLink = createLink(link);
		// pushPath(createdLink);
	};

	const showButton = useMemo(
		() => document?.links?.length > LINKS_COUNT,
		[document?.links?.length]
	);
	if (!document.links) {
		return null;
	}

	return (
		<Plate className={{content: 'document-page__links-plate'}}>
			<div className="document-page__title">Ссылки</div>
			<div className="document-page__links">
				{document.links?.slice(0, LINKS_COUNT).map(link => (
					<LinkButton
						key={link.id}
						className="document-page__link"
						label={`${convertEntityName(link.entityType)} №${link.entityTitle}`}
						leftIcon={linkIcon}
						onClick={() => goToLink(link)}
						type="filled"
					/>
				))}
				{showButton && (
					<InlineButton
						type="accent"
						label={`Показать все (${document.links?.length})`}
						onClick={openModal}
					/>
				)}
			</div>
			{isModalOpen && (
				<Dialog
					isOpen={isModalOpen}
					onRequestClose={closeModal}
					className="document-page__links-dialog"
				>
					<div className="document-page__title--dialog">
						Ссылки
						<span className="document-page__title--dialog-span">
							{` (${document.links.length})`}
						</span>
					</div>
					<div className="document-page__links--dialog">
						{document.links.map(link => (
							<LinkButton
								className="document-page__link"
								label={`${convertEntityName(link.entityType)} №${link.entityTitle}`}
								leftIcon={linkIcon}
								onClick={() => goToLink(link)}
								type="filled"
							/>
						))}
					</div>
				</Dialog>
			)}
		</Plate>
	);
};
