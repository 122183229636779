import {useCallback, useState, ReactNode} from 'react';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import {useExportSpacesMutation} from '../mutations/spaces/useExportSpacesMutation';

const availableDestinations = [ExportTemplatesDestinationId.OBJECT];

export function useSpacesExport(objectId: string): [ReactNode, (objectId: string) => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const {mutateAsync: exportSpaces} = useExportSpacesMutation(objectId);

	const openDialog = useCallback(() => {
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await exportSpaces({templateId: template.id, objectId, createDocument, email});
		},
		[objectId]
	);

	const dialog = (
		<ExportDialog
			objectId={objectId}
			pageKey="objects"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
