import {useExportEventHandler} from './hooks/useExportEventHandler';
import {useExportPending} from './hooks/useExportPending';
import {useProcessingMissedExportEvents} from './hooks/useProcessingMissedExportEvents';
import {useShowExportToasts} from './hooks/useShowExportToasts';

export const DefferedFileExport = () => {
	useExportEventHandler();
	useProcessingMissedExportEvents();
	useShowExportToasts();
	useExportPending();
	return null;
};
