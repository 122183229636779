import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {
	IDefferedExportResponse,
	IExportWorkAcceptanceRequest
} from '@tehzor/tools/interfaces/export';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт приемки работ
 */
export const makeWorkAcceptanceExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	workAcceptanceId: string,
	checkLists?: string[],
	problems?: string[]
) => {
	const params: IExportWorkAcceptanceRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId,
			workAcceptanceId,
			problems,
			checkLists
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		`work-acceptances/${workAcceptanceId}/export`,
		params
	);
	return response.data;
};
