import {CSSProperties, memo, useCallback} from 'react';
import {EditableFieldLabel, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {DatePicker} from '@tehzor/ui-components/src/components/dates';
import {format} from 'date-fns';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {CriticalCheckbox} from './CriticalCheckbox/CriticalCheckbox';
import {useController, useFormContext} from 'react-hook-form';
import {SetValueOptions} from './ProblemForm';

const dateFormat = 'dd.MM.yyyy HH:mm';
const dialogProps = {title: 'Выберите дату'};
const icon = <i className="tz-calendar-20" />;

const disabledDate = (current?: Date, createdAt?: number) => {
	if (!current) {
		return false;
	}

	return current.valueOf() < (createdAt ?? new Date().setHours(0, 0, 0, 0));
};

interface IFixDateProps<S> {
	name: string;
	onSetValue: (name: string, value: unknown, options?: SetValueOptions) => void;
	className?: string;
	style?: CSSProperties;
	label?: string;
	criticalName?: string;
	criticalFieldsSettings?: IObjectFieldSetting;
	criticalDisabled?: boolean;
	required?: boolean;
	validate?: (value: unknown) => boolean | string;
	disabled?: boolean;
	createdAt?: number;
	showTimeSelect?: boolean;
	field?: keyof S;
	errorMessage?: string;
	isDayStartFromEnd?: boolean;
}

const EditableDateMemo = <S extends Record<string, unknown>>({
	className,
	style,
	label = 'Срок устранения',
	criticalFieldsSettings,
	criticalDisabled,
	required,
	validate,
	disabled,
	createdAt,
	showTimeSelect,
	name: dateName,
	criticalName,
	onSetValue,
	errorMessage,
	isDayStartFromEnd = false
}: IFixDateProps<S>) => {
	const locale = useDateFnsLocale();
	const {control} = useFormContext();

	const {
		field: {value: dateValue},
		fieldState: {invalid, error}
	} = useController({
		name: dateName,
		control,
		rules: {
			required: {
				value: required || false,
				message: errorMessage || ''
			},
			validate
		}
	});
	const handleChange = useCallback(
		(v: Date | null) => {
			onSetValue(dateName, v ? v.getTime() : null);
		},
		[onSetValue, dateName]
	);
	const {t} = useTranslation();

	const onClear = useCallback(() => {
		onSetValue(dateName, null);
	}, [onSetValue, dateName]);

	const date = dateValue ? new Date(dateValue) : undefined;
	const prepareDisabledDate = useCallback(
		(cur: Date) => disabledDate(cur, createdAt),
		[createdAt]
	);
	const getTriger = useCallback(
		(props: IDatePickerTriggerProps) => (
			<TextFieldWithForwardedRef
				elementType="div"
				value={props?.value !== undefined ? format(props.value, dateFormat) : ''}
				disabled={disabled}
				error={invalid ? error?.message : undefined}
				icon={icon}
				onClick={props?.toggle}
				ref={props?.ref}
				onClearClick={onClear}
				cleanable={!!props?.value}
			/>
		),
		[disabled, invalid, error, onClear]
	);
	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<DatePicker
				trigger={getTriger}
				value={date}
				datesOptions={{locale, isDayStartFromEnd}}
				disabledDate={prepareDisabledDate}
				dialogProps={dialogProps}
				selectCancelBtnLabel={t('cancelBtn.label')}
				selectApplyBtnLabel={t('applyBtn.label')}
				InlineDatePickerLabel={t('inlineDatePicker.label')}
				useApplyButton
				showTimeSelect={showTimeSelect}
				onChange={handleChange}
			/>
			{criticalFieldsSettings !== undefined && (
				<CriticalCheckbox
					plannedFixDateValue={dateValue}
					disabled={criticalDisabled}
					name={criticalName}
					plannedFixDateName={dateName}
					onSetValue={onSetValue}
					required={criticalFieldsSettings.isRequired}
				/>
			)}
		</div>
	);
};
export const EditableDate = memo(EditableDateMemo) as typeof EditableDateMemo;
