import {deleteLocalInspection} from '@src/cache/actions/inspection/deleteLocalInspection';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {makeInspectionDeleteRequest} from '@src/api/backend/inspection';
import {structuresQueryKeys} from '@src/fsd/src/entities/Structures';
import {invalidateLinkedInspectionsQueries} from '@src/cache/actions/inspection/invalidateLinkedInspectionsQueries';
import {useCallback} from 'react';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {DELETE_SUCCESS} from '@src/store/modules/entities/inspection/constants';

export interface IDeleteInspectionParams {
	objectId: string;
	inspectionId: string;
}

export const useDeleteInspection = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {mutateAsync} = useMutation({
		mutationKey: inspectionsQueryKeys.delete(),
		mutationFn: async (params: IDeleteInspectionParams) => {
			const {objectId, inspectionId} = params;
			return makeInspectionDeleteRequest(objectId, inspectionId);
		},
		onSuccess: (_, variables) => {
			const {objectId, inspectionId} = variables;
			queryClient.removeQueries({queryKey: inspectionsQueryKeys.detail(inspectionId)});
			void queryClient.invalidateQueries({
				queryKey: [...inspectionsQueryKeys.list(), objectId]
			});
			void queryClient.invalidateQueries({queryKey: structuresQueryKeys.inspections()});
			void invalidateLinkedInspectionsQueries(objectId);
			addSuccessToast({title: t('toast.deleteInspection.success.title')});
			dispatch({type: DELETE_SUCCESS, payload: {objectId, inspectionId}});
		},
		onError: () => addErrorToast({title: t('toast.deleteInspection.error.title')})
	});

	return useCallback(
		async (params: IDeleteInspectionParams) => {
			const {objectId, inspectionId} = params;
			const payloadToSave = queryClient.getQueryData(
				inspectionsQueryKeys.savingData(inspectionId)
			);
			if (payloadToSave) {
				await deleteLocalInspection(inspectionId);
				addSuccessToast({title: t('toast.deleteInspection.success.title')});
				return undefined;
			}
			return mutateAsync({objectId, inspectionId});
		},
		[mutateAsync, t]
	);
};
