import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IDefferedExportResponse, IExportSpaceRequest} from '@tehzor/tools/interfaces/export';
import {ISpaceEntityExport} from '@src/interfaces/ISpaceEntityExport';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт помещения
 */
export const makeSpaceExportRequest = async ({
	spaceId,
	templateId,
	createDocument,
	email,
	objectId,
	problems,
	inspections
}: ISpaceEntityExport): Promise<IDefferedExportResponse> => {
	const params: IExportSpaceRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId,
			problems,
			inspections
		}
	};

	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		`spaces/export/${spaceId}`,
		params
	);
	return response.data;
};
