export type IDefferedTasksQueryKey = [] & {
	0: string; // deffered-file-export
	1: string; // tasks
	2: string; // userId
};

export const defferedFileExportQueryKeys = {
	all: () => ['deffered-file-export'],
	tasks: () => [...defferedFileExportQueryKeys.all(), 'tasks'],
	tasksByInitiator: (userId: string) => [...defferedFileExportQueryKeys.tasks(), userId]
};
