import {requestListProblems} from '@src/api/backend/problems';
import {requestWarrantyClaim} from '@src/api/backend/warrantyClaim';
import {requestWarrantyClaims} from '@src/api/backend/warrantyClaims';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {Query, QueryFunctionContext, QueryKey, useQueryClient} from '@tanstack/react-query';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {getDateDifference} from '@tehzor/tools/utils/dates/getDateDifference';
import {endOfDay} from 'date-fns';
import {
	IWarrantyClaimProblemsQueryKey,
	IWarrantyClaimProblemsRepliesQueryKey,
	IWarrantyClaimQueryKey,
	IWarrantyClaimsListQueryKey,
	IWarrantyClaimsProblemsStats,
	warrantyClaimsQueryKeys
} from '../keys';
import {requestWarrantyClaimsStatsData} from '@src/api/backend/warrantyClaims/getStatsData';
import {requestComments} from '@src/api/backend/comments';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';

export const useWarrantyClaimsQueryDefaults = () => {
	const queryClient = useQueryClient();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);

	queryClient.setQueryDefaults(warrantyClaimsQueryKeys.lists(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IWarrantyClaimsListQueryKey>) => {
			const [, , , filters, sort, offset, pageSize] = queryKey;
			return requestWarrantyClaims(filters, sort, offset, pageSize);
		},
		meta: {
			error: 'при загрузке гарантийных обращений'
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(warrantyClaimsQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IWarrantyClaimQueryKey>) => {
			const [, , warrantyClaimId, objectId] = queryKey;
			if (!warrantyClaimId || !objectId) return undefined;

			return requestWarrantyClaim(warrantyClaimId, objectId);
		},
		meta: {
			persist: isOfflineModeAvailable
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(warrantyClaimsQueryKeys.latest(), {
		queryFn: () => {
			const date = new Date();
			date.setHours(0, 0, 0, 0);
			return requestWarrantyClaims({createdAtFrom: date}, {createdAt: false}, 0, 100);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: getDateDifference(new Date(), endOfDay(new Date())) // до конца дня
	});

	queryClient.setQueryDefaults(warrantyClaimsQueryKeys.localList(), {
		queryFn: async ({queryKey}) => {
			const [, , objectId] = queryKey;
			const localClaim = await new Promise<Array<[QueryKey, IListWarrantyClaim | undefined]>>(
				resolve => {
					const local = queryClient.getQueriesData<IListWarrantyClaim>({
						queryKey: warrantyClaimsQueryKeys.details(),
						predicate: (query: Query<IListWarrantyClaim>) => {
							const {data} = query.state;
							if (data?.transferStatus) {
								if (!objectId || objectId === 'all') {
									return true;
								}
								if (objectId === data.objectId) {
									return true;
								}
							}
							return false;
						}
					});
					resolve(local);
				}
			);
			return localClaim.map(([, claim]) => claim) as IListWarrantyClaim[];
		},
		staleTime: 0,
		gcTime: 0,
		networkMode: 'offlineFirst'
	});

	queryClient.setQueryDefaults(warrantyClaimsQueryKeys.problems(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IWarrantyClaimProblemsQueryKey>) => {
			const [, , objectId, claimId] = queryKey;
			return requestListProblems(
				{
					objects: [objectId],
					warrantyClaims: [claimId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});

	queryClient.setQueryDefaults(warrantyClaimsQueryKeys.problemsReplies(), {
		queryFn: async ({
			queryKey
		}: QueryFunctionContext<IWarrantyClaimProblemsRepliesQueryKey>) => {
			const [, , claimId, objectId] = queryKey;
			return requestComments(
				{
					commentTypeId: ICommentTypeId.PROBLEM_COMMENT,
					objects: [objectId],
					warrantyClaims: [claimId],
					official: true
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});

	queryClient.setQueryDefaults(warrantyClaimsQueryKeys.problemsStats(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IWarrantyClaimsProblemsStats>) => {
			const [, , , ...rest] = queryKey;
			return requestWarrantyClaimsStatsData({warrantyClaims: rest});
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});
};
