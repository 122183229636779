import {makeChecksExportRequest} from '@src/api/backend/checks';
import {useMutation} from '@tanstack/react-query';
import {useCallback} from 'react';
import {addErrorToast} from '@tehzor/ui-components';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

interface IExportCheckParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId?: string;
	filters?: Record<string, unknown>;
	sort?: Record<string, boolean>;
	selected?: string[];
}

export const useExportChecks = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const mutation = useMutation({
		mutationKey: checksQueryKeys.export(),
		mutationFn: (params: IExportCheckParams) => {
			const {sort, email, objectId, selected, templateId, filters, createDocument} = params;
			return makeChecksExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				filters,
				sort,
				selected
			);
		},
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});

	/**
	 * Возвращает функцию для экспорта списка проверок
	 *
	 * @param templateId id шаблона экспорта
	 * @param createDocument флаг создания документа
	 * @param email email для отправки
	 * @param objectId id объекта
	 * @param filters фильтры
	 * @param sort сортировка
	 * @param selected выбранные элементы
	 */

	return useCallback(
		(
			templateId: string,
			createDocument: boolean | undefined,
			email: string | undefined,
			objectId?: string,
			filters?: Record<string, unknown>,
			sort?: Record<string, boolean>,
			selected?: string[]
		) => {
			mutation.mutate({
				templateId,
				createDocument,
				email,
				objectId,
				filters,
				sort,
				selected
			});
		},
		[mutation]
	);
};
