import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IDefferedExportTasksState} from './interfaces';
import {ExportTaskStatus} from '@tehzor/tools/interfaces/export/ExportTaskStatus';
import {IExportTasksFinished} from '@tehzor/tools/interfaces/export/IExportTasksFinished';
import {IExportTasksFailed} from '@tehzor/tools/interfaces/export/IExportTasksFailed';

const initialState: IDefferedExportTasksState = {};

export const defferedExportTasksSlice = createSlice({
	name: 'export',
	initialState,
	reducers: {
		/**
		 * Добавляет задачу экспорта с указанным статусом
		 */
		addExportTask(state, action: PayloadAction<{taskId: string; email: boolean}>) {
			state[action.payload.taskId] = {
				status: ExportTaskStatus.PENDING,
				email: action.payload.email,
				toastShown: false
			};
		},
		/**
		 * Изменяет статус отложенной задачи экспорта
		 */
		updateDefferedExportTask(
			state,
			action: PayloadAction<{
				taskId: string;
				status: ExportTaskStatus;
				message: IExportTasksFinished | IExportTasksFailed;
			}>
		) {
			state[action.payload.taskId].status = action.payload.status;
			state[action.payload.taskId].message = action.payload.message;
		},
		/**
		 * Изменяет флаг показа тоста
		 */
		toggleToastShown(state, action: PayloadAction<{taskId: string}>) {
			state[action.payload.taskId].toastShown = !state[action.payload.taskId].toastShown;
		}
	}
});

export const {
	actions: {addExportTask, updateDefferedExportTask, toggleToastShown},
	reducer: defferedExportReducer
} = defferedExportTasksSlice;
