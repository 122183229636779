import {memo, useState} from 'react';
import * as React from 'react';
import {Info} from './info/Info';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {ViewTabsMobile, ViewTabsMobileVariants} from './ViewTabsMobile';
import {useLegalMainContent} from '../hooks/useLegalMainContent';
import {BankAccountItem} from './BankAccountItem';
import {useBankAccounts, useBankAccountsArray} from '@src/core/hooks/queries/bankAccounts';
import {
	useAuthorizedPersons,
	useAuthorizedPersonsArray
} from '@src/core/hooks/queries/authorizedPersons';
import {AuthorizedPersonItem} from './AuthorizedPersonItem';
import {AddButton} from './actions/AddButton';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';
import {Plate} from '@tehzor/ui-components';

interface IMobileProps {
	legal?: ILegalEntity;
}

export const Mobile = memo(({legal}: IMobileProps) => {
	const [activeTab, setActiveTab] = useState<ViewTabsMobileVariants>('main');
	const mainContentItems = useLegalMainContent(legal);
	const bankAccounts = useBankAccounts();
	const bankAccountsArray = useBankAccountsArray(legal?.id, bankAccounts);
	const {data: authorizedPersons} = useAuthorizedPersons();
	const authorizedPersonsArray = useAuthorizedPersonsArray(legal?.id, authorizedPersons);

	let items: React.ReactNode[] = [];

	if (!legal) {
		return null;
	}

	const isAuthorizedPersonsMode = activeTab === 'authorizedPersons';

	switch (activeTab) {
		case 'main': {
			items = mainContentItems;
			break;
		}
		case 'bankAccounts': {
			items = bankAccountsArray.map(el => <BankAccountItem bankAccount={el} />);
			items.push(
				<AddButton
					key="bankAccountsAddButton"
					isAuthorizedPersonsMode={isAuthorizedPersonsMode}
					companyId={legal.companyId}
					legalEntityId={legal.id}
				/>
			);
			break;
		}
		case 'authorizedPersons': {
			items = authorizedPersonsArray.map(el => (
				<AuthorizedPersonItem authorizedPerson={el} />
			));
			items.push(
				<AddButton
					key="authorizedPersonsAddButton"
					isAuthorizedPersonsMode={isAuthorizedPersonsMode}
					companyId={legal.companyId}
					legalEntityId={legal.id}
				/>
			);
			break;
		}
		default:
			items = mainContentItems;
	}

	return (
		<div className="page-cont legal-page">
			<PageBreadcrumbs legal={legal} />
			<ViewTabsMobile
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				bankAccountsCount={bankAccountsArray.length}
				authorizedPersonsCount={authorizedPersonsArray.length}
			/>
			<Info legal={legal} />

			{/* Нужно для отображения кнопки добавить */}
			{items.length > 1 ? (
				<Plate className={{content: 'legal-page__content-mobile'}}>{items}</Plate>
			) : (
				items
			)}
		</div>
	);
});
