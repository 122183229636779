import {useMutation} from '@tanstack/react-query';
import {makeSpaceExportRequest} from '@src/api/backend/space';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import IError from '@tehzor/tools/interfaces/IError';
import {IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {ISpaceEntityExport} from '@src/interfaces/ISpaceEntityExport';
import {addErrorToast} from '@tehzor/ui-components';
import {IExportSpacesParams} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

export const useExportSpace = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation<IDefferedExportResponse, IError, ISpaceEntityExport>({
		mutationKey: spacesQueryKeys.edit(),
		mutationFn: (params: ISpaceEntityExport) => makeSpaceExportRequest(params),
		onSuccess: (response: IDefferedExportResponse, {email}: IExportSpacesParams) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!email}));
		},
		onError: () => addErrorToast({title: t('toast.exportSpace.error.title')})
	});
};
