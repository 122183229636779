import {useCallback} from 'react';
import {
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	InlineButton,
	Plate,
	StatusSelect
} from '@tehzor/ui-components';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useAsyncFn} from 'react-use';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';
import SpaceOwners from './SpaceOwners';
import {useEditableWarrantyClaimAttachmentsDialog} from '@src/components/EditableWarrantyClaimAttachmentsDialog/hooks/useEditableWarrantyClaimAttachmentsDialog';
import Documents from './Documents';
import {ExpandableMobileInfo} from '@src/components/ExpandableMobileInfo';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {RegistrationDateMobile} from './registrationDate.mobile';
import {useWarrantyClaimsStatuses} from '@src/core/hooks/queries/warrantyClaimsStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {IEntitySettings} from '../../../../core/hooks/settings/useEntitySettings';
import {CustomField} from '../../../../components/CustomField';
import {useEditWarrantyClaim} from '@src/core/hooks/mutations/warrantyClaims/useEditWarrantyClaim';
import {useEditWarrantyClaimStatus} from '@src/core/hooks/mutations/warrantyClaims/useEditStatusWarrantyClaim';

interface IMobileInfoProps {
	objectId: string;
	warrantyClaim: ILinkedWarrantyClaim;
	settings?: IEntitySettings;
}

const calendarIcon = <i className="tz-calendar-20" />;
const editIcon = <i className="tz-edit-16" />;
const statusIcon = <i className="tz-status-20" />;
const documentsIcon = <i className="tz-document-20" />;
const spaceIcon = <i className="tz-space-24" />;

const MobileInfo = ({objectId, warrantyClaim, settings}: IMobileInfoProps) => {
	const {t} = useTranslation();
	const {mutateAsync: editWarrantyClaim} = useEditWarrantyClaim();

	const {pushPath} = useChangePath();
	const spaceTitle = useSpaceTitle(warrantyClaim?.space);
	const {data: statuses} = useWarrantyClaimsStatuses();
	const translatedStatuses = useTranslatedDictionary(
		dictionaryKeys.warrantyClaimStatuses,
		statuses
	);
	const {mutateAsync: editWarrantyClaimStatus} = useEditWarrantyClaimStatus();

	const permissions = useWarrantyClaimsPermissions(objectId);
	const [editingAttachmentsDialog, openEditAttachmentsDialog] =
		useEditableWarrantyClaimAttachmentsDialog(objectId, warrantyClaim.id, warrantyClaim);

	const [, handleChange] = useAsyncFn(
		async (v: WarrantyClaimStatusId) => {
			if (warrantyClaim) {
				await editWarrantyClaimStatus({objectId, claimId: warrantyClaim.id, statusId: v});
			}
		},
		[objectId, warrantyClaim]
	);

	const [, handleRegDateChange] = useAsyncFn(
		async (value: Date) => {
			if (warrantyClaim) {
				await editWarrantyClaim({
					objectId,
					claimId: warrantyClaim.id,
					data: {
						registrationDate: value.getTime()
					}
				});
			}
		},
		[objectId, warrantyClaim]
	);

	const handleClick = useCallback(() => {
		if (warrantyClaim) {
			if (warrantyClaim.objectId && warrantyClaim.links?.spaceId) {
				pushPath(
					`/objects/${warrantyClaim.objectId}/spaces/${warrantyClaim.links.spaceId}`
				);
			}
		}
	}, [warrantyClaim]);
	const custom = settings?.custom;
	return (
		<Plate>
			<EntityGrid withBorders>
				<EntityGridItem
					// className="space-page__status"
					icon={statusIcon}
					label={t('warrantyClaimPage.info.entityGridItem.status.label')}
					inline
					fullRow
				>
					<StatusSelect
						className="warranty-claim-page__d-entities-status"
						statuses={translatedStatuses}
						disabled={!permissions.canEditStatus}
						value={warrantyClaim?.status}
						onChange={handleChange}
					/>
				</EntityGridItem>

				<SpaceOwners
					name={warrantyClaim.spaceOwner?.name}
					phone={warrantyClaim?.replyPhone}
					email={warrantyClaim?.replyEmail}
					claimer={warrantyClaim?.claimerName}
				/>

				<EntityGridItem
					label={t('warrantyClaimPage.info.entityGridItem.formalNote.label')}
					icon={documentsIcon}
					fullRow
					buttons={
						<InlineButton
							className="warranty-claim-page__documents-edit-icon"
							type="accent"
							leftIcon={editIcon}
							onClick={openEditAttachmentsDialog}
						/>
					}
				>
					<Documents warrantyClaim={warrantyClaim} />
				</EntityGridItem>
				<RegistrationDateMobile
					value={warrantyClaim.registrationDate}
					onChange={handleRegDateChange}
					canEdit={permissions.canEdit}
				/>
				{custom &&
					Object.values(custom).map(customSetting => (
						<CustomField
							fields={warrantyClaim.customFields}
							setting={customSetting}
						/>
					))}
			</EntityGrid>
			<ExpandableMobileInfo noBorder>
				<EntityGrid withBorders>
					<EditorInfo
						icon={calendarIcon}
						label={t('warrantyClaimPage.info.editorInfo.created.label')}
						date={warrantyClaim?.createdAt}
						user={warrantyClaim?.createdBy}
						fullRow
					/>

					{warrantyClaim?.createdAt !== warrantyClaim?.modifiedAt && (
						<EditorInfo
							icon={calendarIcon}
							label={t('warrantyClaimPage.info.editorInfo.updated.label')}
							date={warrantyClaim?.modifiedAt}
							user={warrantyClaim?.modifiedBy}
							fullRow
						/>
					)}
					<EntityGridItem className={{content: 'warranty-claim-page__space-btn'}}>
						<InlineButton
							label={spaceTitle}
							type="accent"
							onClick={handleClick}
							leftIcon={spaceIcon}
						/>
					</EntityGridItem>
				</EntityGrid>
			</ExpandableMobileInfo>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default MobileInfo;
