import {io} from 'socket.io-client';
import {localAuthStorage} from './localAuthStorage';
import detectSocketTimeout from '../utils/detectSocketTimeout';
import EventEmitter from 'events';
import INotificationMessage from '../interfaces/INotificationMessage';
import {MissingTokenError} from '../errors/MissingTokenError';
import {MissingDeviceError} from '../errors/MissingDeviceError';
import {Socket} from 'socket.io-client/build/esm/socket';

type StoreNotificationMsgAction = (message: INotificationMessage) => void;

interface IParams {
	// eslint-disable-next-line @typescript-eslint/ban-types
	[key: string]: string | number | boolean | object | Blob | null | undefined;
}

/**
 * Базовый singleton класс для взаимодействия с backend'ом
 */
class BaseSocketConnector {
	// eslint-disable-next-line no-undef
	private _socket?: Socket;

	// private _stream?: any;

	private _store: any;

	private _storeNotificationMsgAction: StoreNotificationMsgAction;

	private _eventEmitter = new EventEmitter();

	/**
	 * Создает подключение к api
	 *
	 * @param {string} url адрес api
	 */
	connect = (url?: string) => {
		if (!url) {
			throw new Error('WS connection Aborted. URL is not found');
		}
		this._socket = io(url, {
			path: '/api/main',
			transports: ['websocket']
		});
		// this._stream = ss(this._socket, {});
		this._socket.on('connect', this._onConnect);
		this._socket.on('disconnect', this._onDisconnect);
	};

	/**
	 * Инициализирует экземпляр класса
	 *
	 * @param store Redux store
	 * @param storeNotificationMsgAction
	 */
	initialize = (store: any, storeNotificationMsgAction: StoreNotificationMsgAction) => {
		if (!store || !storeNotificationMsgAction) {
			throw new Error(
				"Params ('store', 'connStatusAction', 'storeNotificationMsgAction') are not found"
			);
		}
		this._store = store;
		this._storeNotificationMsgAction = storeNotificationMsgAction;
	};

	/**
	 * Подписывается на получение сообщений-уведомлений
	 *
	 * @return {Promise}
	 */
	subscribeOnNotifications = async () => {
		await this._sendAuthorizedRequest('subscribeOnNotifications');
		this._subscribe('notificationMessageAdded', this._storeNotificationMessage, true);
	};

	/**
	 * Отписывается от получения сообщений-уведомлений
	 *
	 * @return {Promise}
	 */
	unsubscribeFromNotifications = async () => {
		this._unsubscribe('notificationMessageAdded', this._storeNotificationMessage);
		await this._sendSimpleRequest('unsubscribeFromNotifications');
	};

	/**
	 * Получает список непрочитанных сообщений
	 *
	 * @param {number} offset индекс начального элемента
	 * @param {number} limit количество элементов
	 * @returns {Promise}
	 */
	getNotificationMessages = (offset: number, limit: number) => {
		const params: {offset?: number; limit?: number} = {};
		if (offset) {
			params.offset = offset;
		}
		if (limit) {
			params.limit = limit;
		}
		return this._sendAuthorizedRequest('getNotificationMessages', params);
	};

	/**
	 * Помечает сообщение как прочитанное
	 *
	 * @param {string} id id сообщения
	 * @returns {*}
	 */
	deleteNotificationMessage = (id: string) => {
		const params = {id};
		return this._sendAuthorizedRequest('deleteNotificationMessage', params);
	};

	/**
	 * Помечает все сообщения как прочитанные
	 *
	 * @returns {*}
	 */
	deleteAllNotificationMessages = () =>
		this._sendAuthorizedRequest('deleteAllNotificationMessages');

	/**
	 * Получает типы уведомлений
	 *
	 * @returns {*}
	 */
	getNotificationTypes = () => this._sendAuthorizedRequest('getNotificationTypes');

	/**
	 * Получает каналы рассылки уведомлений
	 *
	 * @returns {*}
	 */
	getNotificationChannels = () => this._sendAuthorizedRequest('getNotificationChannels');

	/**
	 * Получает подписки на уведомления
	 *
	 * @returns {*}
	 */
	getNotificationSubscriptions = () =>
		this._sendAuthorizedRequest('getNotificationSubscriptions');

	/**
	 * Измененяет подписки на уведомления
	 *
	 * @param {Array} subscriptions подписки
	 * @returns {*}
	 */
	editNotificationSubscriptions = (subscriptions: any) => {
		const params = {
			subscriptions
		};
		return this._sendAuthorizedRequest('editNotificationSubscriptions', params);
	};

	/**
	 * Получает интервалы уведомлений
	 *
	 * @returns {*}
	 */
	getNotificationIntervals = () => this._sendAuthorizedRequest('getNotificationIntervals');

	/**
	 * Получает периоды уведомлений
	 *
	 * @returns {*}
	 */
	getNotificationPeriods = () => this._sendAuthorizedRequest('getNotificationPeriods');

	/**
	 * Получает публичный ключ для шифрования push-уведомлений
	 *
	 * @returns {*}
	 */
	getWebPushPublicKey = () => this._sendAuthorizedRequest('getWebPushPublicKey');

	/**
	 * Добавляет или изменяет подписку на web push-уведомления
	 *
	 * @param {PushSubscription} subscription объект подписки
	 * @param {string} fingerprint fingerprint подписываемого устройства
	 */
	updateWebPushSubscription = async (subscription: PushSubscription, fingerprint: string) => {
		const deviceId = await localAuthStorage.getDeviceId();

		const params = {subscription, fingerprint, deviceId};
		return this._sendAuthorizedRequest('updateWebPushSubscription', params);
	};

	/**
	 * Удаляет подписку на web push-уведомления
	 *
	 * @param {string} deviceId id подписываемого устройства
	 * @returns {*}
	 */
	deleteWebPushSubscription = (deviceId: string) => {
		const params = {deviceId};
		return this._sendAuthorizedRequest('deleteWebPushSubscription', params);
	};

	/**
	 * Отправляет запрос через WebSocket и ожидает данные в ответ
	 *
	 * @param {string} methodName название метода
	 * @param {Object} params параметры запроса
	 * @return {Promise}
	 * @protected
	 */
	protected _sendRequest = <R = any>(methodName: string, params: IParams = {}): Promise<R> =>
		new Promise((resolve, reject) => {
			if (!this._socket) {
				reject({
					code: 600,
					error: 'NoActiveConnection',
					message: 'No active connection'
				});
			} else {
				this._socket.emit(
					methodName,
					params,
					detectSocketTimeout(data => {
						if (data && data.error) {
							reject(data);
						}
						resolve(data);
					})
				);
			}
		});

	/**
	 * Отправляет запрос, прикрепляя к нему токен авторизации
	 *
	 * @param {string} methodName название метода
	 * @param {IParams} params параметры запроса
	 * @return {Promise}
	 * @protected
	 */
	protected _sendAuthorizedRequest = async <R>(
		methodName: string,
		params: IParams = {}
	): Promise<R> => {
		const accessToken = await localAuthStorage.getAccessToken();
		const deviceId = await localAuthStorage.getDeviceId();

		if (!accessToken) {
			throw new MissingTokenError();
		}
		if (!deviceId) {
			throw new MissingDeviceError();
		}

		params.accessToken = accessToken;
		params.deviceId = deviceId;

		return this._sendRequest<R>(methodName, params);
	};

	/**
	 * Отправляет простой запрос через WebSocket без ожидания данных в ответ
	 *
	 * @param {string} methodName название метода
	 * @param {IParams} params параметры запроса
	 * @protected
	 */
	protected _sendSimpleRequest = async (
		methodName: string,
		params: IParams = {}
	): Promise<void> => {
		if (!this._socket) {
			return Promise.reject({
				code: 600,
				error: 'NoActiveConnection',
				message: 'No active connection'
			});
		}
		this._socket.emit(methodName, params);
	};

	/**
	 * Отправляет простой запрос, прикрепляя к нему токен авторизации
	 *
	 * @param {String} methodName название метода
	 * @param {Object} params параметры запроса
	 * @return {Promise}
	 * @protected
	 */
	protected _sendAuthorizedSimpleRequest = async (
		methodName: string,
		params: IParams = {}
	): Promise<void> => {
		const accessToken = await localAuthStorage.getAccessToken();
		if (!accessToken) {
			throw new MissingTokenError();
		}
		params.accessToken = accessToken;
		await this._sendSimpleRequest(methodName, params);
	};

	/**
	 * Подписывается на WebSocket событие
	 *
	 * @param {string} eventName название события
	 * @param {Function} callback callback
	 * @param unique
	 * @protected
	 */
	protected _subscribe = (
		eventName: string,
		callback: (...args: any[]) => void,
		unique = false
	) => {
		if (this._socket && (!unique || (unique && !this._socket.hasListeners(eventName)))) {
			this._socket.on(eventName, callback);
		}
	};

	/**
	 * Отписывается от WebSocket события
	 *
	 * @param {string} eventName название события
	 * @param {Function} callback callback
	 * @protected
	 */
	protected _unsubscribe = (eventName: string, callback?: (...args: any[]) => void) => {
		if (this._socket) {
			this._socket.off(eventName, callback);
		}
	};

	/**
	 * Обработывает событие при соединении с api
	 *
	 * @private
	 */
	private _onConnect = () => {
		this._eventEmitter.emit('connectionStatusChange', true);
	};

	/**
	 * Обработывает событие при отключении от api
	 *
	 * @param {string} reason причина отключения
	 * @private
	 */
	private _onDisconnect = (reason: string) => {
		this._eventEmitter.emit('connectionStatusChange', false);

		if (reason === 'io server disconnect' && this._socket) {
			this._socket.connect();
		}
	};

	/**
	 * Сохраняет в store сообщение-уведомление
	 *
	 * @param {INotificationMessage} message сообщение
	 * @private
	 */
	private _storeNotificationMessage = (message: INotificationMessage) => {
		if (message && typeof message === 'object') {
			this._store.dispatch(this._storeNotificationMsgAction(message));
		}
	};
}

export default BaseSocketConnector;
