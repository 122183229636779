import getEmailsArray from '@src/utils/getEmailsArray';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {
	IDefferedExportResponse,
	IExportChecksFilters,
	IExportChecksRequest
} from '@tehzor/tools/interfaces/export';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import getTimezone from '@tehzor/tools/utils/getTimezone';

/**
 * Отправляет запрос на экспорт проверок
 */
export const makeChecksExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId?: string,
	filters?: Omit<IExportChecksFilters, 'selected'>,
	sort?: Record<string, boolean>,
	selected?: string[]
): Promise<IDefferedExportResponse> => {
	const params: IExportChecksRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId,
			filters: {
				...filters,
				selected
			},
			sort: convertSortParam(sort)
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		'checks/export',
		params
	);
	return response.data;
};
