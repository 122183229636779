import {ICustomFieldSetting} from '@tehzor/tools/interfaces/fields';
import {IWarrantyClaimField} from '../interfaces/IWarrantyClaimField';

export const getCustomFields = (
	custom: Record<string, ICustomFieldSetting> | undefined
): IWarrantyClaimField[] => {
	if (!custom) {
		return [];
	}

	return Object.values(custom).map(customItem => ({
		id: customItem.fieldKey,
		name: customItem.fieldName,
		iconName: customItem.iconName,
		isCustom: true,
		inputType: 'text'
	}));
};
