import {IWarrantyClaimField} from '../../../interfaces/IWarrantyClaimField';
import {WarrantyClaimSearchField} from '@tehzor/tools/enums/WarrantyClaimSearchField';

export const warrantyClaimsFields: IWarrantyClaimField[] = [
	{
		id: WarrantyClaimSearchField.CLAIM_NUMBER,
		name: '№ обращения',
		iconName: 'tz-number-20'
	}
];
