export const convertEntityName = (entity: string): string => {
	switch (entity) {
		case 'check':
			return 'Проверка';
		case 'check-list':
			return 'Чек-лист';
		case 'check-lists':
			return 'Список чек-листов';
		case 'checks':
			return 'Список проверок';
		case 'checks-problems':
			return 'Список проверок с нарушениями';
		case 'comment':
			return 'Комментарий';
		case 'inspection':
			return 'Осмотр';
		case 'inspections':
			return 'Список осмотров';
		case 'internal-acceptance':
			return 'Внутренняя приемка';
		case 'internal-acceptances':
			return 'Список внутренних приемок';
		case 'object':
			return 'Объект';
		case 'problem':
			return 'Нарушение';
		case 'problems':
			return 'Список нарушений';
		case 'space':
			return 'Помещение';
		case 'unit-handover':
			return 'Передача собственнику';
		case 'warranty-claim':
			return 'Гарантийное обращение';
		case 'warranty-claims':
			return 'Список гарантийных обращений';
		case 'work-acceptance':
			return 'Приемка работ';
		case 'work-acceptances':
			return 'Список приемок работ';
		default:
			return 'Ссылка';
	}
};
