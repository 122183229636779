import {defferedFileExportQueryKeys} from '@src/api/cache/defferedFileExport/keys';
import {useQuery} from '@tanstack/react-query';
import useAppSelector from '../../useAppSelector';
import {extractUserId} from '@src/store/modules/auth/profile/selectors';
import {IDefferedTasksGetByInitiatorResponse} from '@src/api/backend/defferedFileExport/getDefferedTasks';
import IError from '@tehzor/tools/interfaces/IError';

export const useDefferedExportTasksForCurrentUser = () => {
	const userId = useAppSelector(extractUserId);
	return useQuery<IDefferedTasksGetByInitiatorResponse, IError>({
		queryKey: defferedFileExportQueryKeys.tasksByInitiator(userId)
	});
};
