import {IGetPaginateInspectionsResponse} from '@src/api/backend/inspections';
import {useInspectionsList, useLocalInspections} from '@src/core/hooks/queries/inspections/hooks';
import {useMemo} from 'react';
import {convertInspections} from '../utils/convertInspections';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';

interface IPreparedInspectionsParams {
	objectId: string;
	cacheData?: IGetPaginateInspectionsResponse;
}

export const usePreparedInspections = ({objectId, cacheData}: IPreparedInspectionsParams) => {
	const {data: localInspections} = useLocalInspections(objectId);
	const inspections = useInspectionsList(cacheData);
	const networkStatus = useOnlineManager();
	const user = useAppSelector(extractUserProfile);

	const {data: groupsMap} = useWorkingGroupsAsMap();
	const {data: usersMap} = useUsersAsMap();

	return useMemo(
		() =>
			convertInspections(
				networkStatus
					? [...(localInspections || []), ...(inspections || [])]
					: [...(localInspections || [])],
				networkStatus,
				groupsMap,
				usersMap,
				user
			),
		[localInspections, inspections, networkStatus, groupsMap, usersMap, user]
	);
};
