import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';

export type IMoveWarrantyClaimResponse = IWarrantyClaim;

/**
 * Перемещает гарантийное обращение
 */
export const makeWarrantyClaimMoveRequest = async (
	objectId: string,
	claimId: string,
	toObjectId: string,
	toSpaceId: string
) => {
	const response = await httpRequests.withToken.put<IMoveWarrantyClaimResponse>(
		`warranty-claims/move/${claimId}`,
		{
			objectId,
			toObjectId,
			toSpaceId
		}
	);

	return response.data;
};
