import {memo, useState} from 'react';
import {EntityGridItem, InlineButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useEditableSpaceOwnerDialog} from '@src/components/EditableSpaceOwnerDialog/hooks/useEditableSpaceOwnerDialog';
import {useAddingSpaceOwnerDialog} from '@src/components/AddingSpaceOwnerDialog/hooks/useAddingSpaceOwnerDialog';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';
import {useAllSpaceOwnersForSpaceAsArray} from '@src/core/hooks/queries/spaceOwners/hooks';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

interface SpaceOwnerProps {
	objectId: string;
	spaceId: string;
}

const spaceOwnerIcon = <i className="tz-user-owner-20" />;
const plusIcon = <i className="tz-plus-20" />;
const editIcon = <i className="tz-edit-16" />;

export const SpaceOwners = memo(({objectId, spaceId}: SpaceOwnerProps) => {
	const owners = useAllSpaceOwnersForSpaceAsArray(spaceId);
	const {data: object} = useObject(objectId);
	const roles = useAppSelector(extractUserRoles);
	const {t} = useTranslation();
	const [activeOwner, setActiveOwner] = useState<ISpaceOwner>();

	// Диалог добавления собственника
	const [addingDialog, openAddingDialog] = useAddingSpaceOwnerDialog(object?.companyId, spaceId);
	// Диалог редактирования собственника
	const [editDialog, openEditDialog] = useEditableSpaceOwnerDialog(
		spaceId,
		setActiveOwner,
		activeOwner
	);
	const canAdd = hasPermission(roles, 'space-owners-add');
	const canEdit = hasPermission(roles, 'space-owners-edit');

	return (
		<>
			<EntityGridItem
				className={{content: 'space-page__owners'}}
				label={t('spacePage.info.spaceOwners')}
				icon={spaceOwnerIcon}
				fullRow
				buttons={
					canAdd && (
						<InlineButton
							type="accent"
							leftIcon={plusIcon}
							onClick={openAddingDialog}
							className="space-page__info-edit-icon"
						/>
					)
				}
			>
				{owners.length
					? owners.map(owner => (
							<div
								key={owner.id}
								className="space-page__owner"
							>
								<div className="space-page__owner-name">
									{owner.name}
									{canEdit && (
										<InlineButton
											className="space-page__owner-edit-icon"
											type="accent"
											leftIcon={editIcon}
											onClick={() => {
												openEditDialog();
												setActiveOwner(owner);
											}}
										/>
									)}
								</div>

								{owner.phone !== undefined && (
									<div className="space-page__owner-contact">
										<a href={`tel:${owner.phone}`}>{owner.phone}</a>
									</div>
								)}

								{owner.additionalPhone !== undefined && (
									<div className="space-page__owner-contact">
										<a href={`tel:${owner.additionalPhone}`}>
											{owner.additionalPhone}
										</a>
									</div>
								)}

								{owner.email !== undefined && (
									<div className="space-page__owner-contact">
										<a href={`mailto:${owner.email}`}>{owner.email}</a>
									</div>
								)}

								{owner.additionalEmail !== undefined && (
									<div className="space-page__owner-contact">
										<a href={`mailto:${owner.additionalEmail}`}>
											{owner.additionalEmail}
										</a>
									</div>
								)}

								{owner.comment !== undefined && (
									<div className="space-page__owner-contact">
										<span className="space-page__owner-contact-description">
											{owner.comment}
										</span>
									</div>
								)}
							</div>
					  ))
					: null}
			</EntityGridItem>

			{canAdd && addingDialog}
			{canEdit && editDialog}
		</>
	);
});
