import {useMemo} from 'react';
import {add, getWeek, getWeeksInMonth, startOfMonth, startOfWeek} from 'date-fns';
import {IDatesOptions} from '../Calendar';

export interface IDayData {
	date: Date;
	day: number;
}

export interface IWeekData {
	weekIndex: number;
	days: IDayData[];
}

const DayStartFromEndTime = {
	hour: 23,
	min: 59,
	sec: 0,
	ms: 0,
};

export const useDaysGrid = (month: number, year: number, datesOptions?: IDatesOptions) => useMemo(() => {
		const date = new Date(year, month, 1);
		const startDate = startOfMonth(date);
		const startWeek = startOfWeek(startDate, datesOptions);
		const weeksCount = getWeeksInMonth(date, datesOptions);
		const startWeekIndex = getWeek(startWeek, datesOptions);

		const weeks: IWeekData[] = [];
		for (let i = 0; i < weeksCount; i++) {
			const days: IDayData[] = [];
			for (let j = 0; j < 7; j++) {
				const d = add(startWeek, {weeks: i, days: j});
				days.push({
					date: datesOptions && datesOptions.isDayStartFromEnd
						? new Date(d.setHours(DayStartFromEndTime.hour, DayStartFromEndTime.min, DayStartFromEndTime.sec, DayStartFromEndTime.ms))
						: d,
					day: d.getDate()
				});
			}
			weeks.push({
				weekIndex: startWeekIndex + i,
				days
			});
		}
		return weeks;
	}, [month, year, datesOptions]);
