import {combineReducers} from 'redux';
import {PersistedState, persistReducer} from 'redux-persist';
import {appVersion, formPersistConfig} from '@src/store/persistentStorage/formPersistConfig';
import createMigrate from 'redux-persist/es/createMigrate';
import {defferedExportReducer} from './export/slice';

const defferedTasksReducer = combineReducers({
	export: defferedExportReducer
});

const migrations = {
	[appVersion]: (state: ReturnType<typeof defferedTasksReducer> & PersistedState) =>
		({
			export: state.export
		}) as unknown as PersistedState
};

const migrate = createMigrate(migrations, {debug: process.env.NODE_ENV === 'development'});

export const defferedTasks = persistReducer(
	formPersistConfig<ReturnType<typeof defferedTasksReducer>>('defferedTasks', {migrate}),
	defferedTasksReducer
);
