import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {
	IDefferedExportResponse,
	IExportOwnerAcceptanceRequest
} from '@tehzor/tools/interfaces/export';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт передачи собственнику
 */
export const makeOwnerAcptExportRequest = async (
	templateId: string,
	objectId: string,
	acceptanceId: string,
	problems?: string[],
	createDocument?: boolean,
	email?: string
): Promise<IDefferedExportResponse> => {
	const params: IExportOwnerAcceptanceRequest = {
		templateId,
		timezone: getTimezone(),
		createDocument,
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId,
			problems
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		`owner-acceptances/${acceptanceId}/export`,
		params
	);
	return response.data;
};
