import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';

export type IEditWarrantyClaimResponse = IWarrantyClaim;

export interface IEditWarrantyClaimRequest {
	objectId: string;
	claimId: string;
	data: ISavingWarrantyClaim;
}

/**
 * Изменяет гарантийное обращение
 */
export const makeWarrantyClaimEditRequest = async ({
	objectId,
	claimId,
	data
}: IEditWarrantyClaimRequest) => {
	const response = await httpRequests.withToken.put<IEditWarrantyClaimResponse>(
		`warranty-claims/${claimId}`,
		{
			objectId,
			...data
		}
	);

	return response.data;
};
