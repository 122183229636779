import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {
	IDefferedExportResponse,
	IExportWarrantyClaimRequest
} from '@tehzor/tools/interfaces/export';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт гарантийного обращения
 */
export const makeWarrantyClaimExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	claimId: string,
	problems?: string[]
): Promise<IDefferedExportResponse> => {
	const params: IExportWarrantyClaimRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId,
			problems
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		`warranty-claims/${claimId}/export`,
		params
	);
	return response.data;
};
