import {StatsTreePanel} from '@tehzor/ui-components';
import ProblemsStatsChart from './ProblemsStatsChart';
import {StatsByStatus} from '@src/utils/statsAggregators/problemsByStatuses';
import {useNavigateProblems} from '@src/pages/StatisticsPage/hooks/useNavigateProblems';
import {sumStatsValues} from '@src/pages/StatisticsPage/utils/sumStatsValues';
import {useUser} from '@src/core/hooks/queries/users/hooks';

interface IWorkerStatsProps {
	userId: string;
	stats?: StatsByStatus;
	level?: number;
}

export const WorkerStats = ({userId, stats, level}: IWorkerStatsProps) => {
	const totalCount = stats?.expired
		? sumStatsValues(stats) - (stats?.expired || 0)
		: sumStatsValues(stats);

	const {data: user} = useUser(userId);

	const navigate = useNavigateProblems(
		{
			navProps: [
				{
					type: 'performers',
					prop: user?.id
				}
			],
			page: 'warranty'
		},
		undefined
	);

	const handleClick = (status: string) => {
		navigate(status);
	};

	if (!user) {
		return null;
	}

	return (
		<StatsTreePanel
			id={user.id}
			title={user.fullName}
			subTitle={user.position}
			level={level}
			permanentContent={
				<ProblemsStatsChart
					onClick={handleClick}
					stats={stats}
					totalCount={totalCount}
					level={level}
				/>
			}
		/>
	);
};
