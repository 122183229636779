import * as types from '@src/constants/notifications/webPushSubscriptions';
import SocketConnector from '../../api/SocketConnector';
import IError from '@tehzor/tools/interfaces/IError';
import IWebPushSubscription from '@tehzor/tools/interfaces/IWebPushSubscription';
import {unsubscribeFromWebPush} from '@src/utils/webPushHelpers';
import {isServiceWorkerSupported} from '@src/core/offlineMode/utils/isServiceWorkerSupported';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';

const socketConnector = SocketConnector.instance;

const getPublicKeyRequest = () => ({
	type: types.GET_WEB_PUSH_PUBLIC_KEY_REQUEST
});

const getPublicKeySuccess = (response: string) => ({
	type: types.GET_WEB_PUSH_PUBLIC_KEY_SUCCESS,
	payload: response
});

const getPublicKeyFailure = (error: IError) => ({
	type: types.GET_WEB_PUSH_PUBLIC_KEY_FAILURE,
	payload: {...error}
});

/**
 * Получает публичный ключ для шифрования push-уведомлений
 *
 * @returns {Object}
 */
export const getPublicKey = (): AppThunkAction<Promise<unknown>> => dispatch =>
	dispatch(
		createApiAction(getPublicKeyRequest, getPublicKeySuccess, getPublicKeyFailure, () =>
			socketConnector.getWebPushPublicKey()
		)
	);

const updateWebPushSubscriptionRequest = () => ({
	type: types.UPDATE_WEB_PUSH_SUBSCRIPTION_REQUEST
});

const updateWebPushSubscriptionSuccess = (response: IWebPushSubscription) => ({
	type: types.UPDATE_WEB_PUSH_SUBSCRIPTION_SUCCESS,
	payload: {...response}
});

const updateWebPushSubscriptionFailure = (error: IError) => ({
	type: types.UPDATE_WEB_PUSH_SUBSCRIPTION_FAILURE,
	payload: {...error}
});

/**
 * Добавляет или изменяет подписку на web push-уведомления
 *
 * @param {object} subscription объект подписки
 * @param {string} deviceId id подписываемого устройства
 * @param {string} device название подписываемого устройства
 * @returns {Object}
 */
export const updateWebPushSubscription =
	(subscription: PushSubscription, fingerprint: string): AppThunkAction<Promise<unknown>> =>
	dispatch =>
		dispatch(
			createApiAction(
				updateWebPushSubscriptionRequest,
				updateWebPushSubscriptionSuccess,
				updateWebPushSubscriptionFailure,
				() => socketConnector.updateWebPushSubscription(subscription, fingerprint)
			)
		);

const deleteWebPushSubscriptionRequest = () => ({
	type: types.DELETE_WEB_PUSH_SUBSCRIPTION_REQUEST
});

const deleteWebPushSubscriptionSuccess = () => ({
	type: types.DELETE_WEB_PUSH_SUBSCRIPTION_SUCCESS
});

const deleteWebPushSubscriptionFailure = (error: IError) => ({
	type: types.DELETE_WEB_PUSH_SUBSCRIPTION_FAILURE,
	payload: {...error}
});

/**
 * Удаляет подписку на web push-уведомления
 *
 * @param {string} deviceId id подписываемого устройства
 * @returns {Object}
 */
export const deleteWebPushSubscription =
	(deviceId: string): AppThunkAction<Promise<unknown>> =>
	async dispatch => {
		if (isServiceWorkerSupported()) {
			await unsubscribeFromWebPush();
			await dispatch(
				createApiAction(
					deleteWebPushSubscriptionRequest,
					deleteWebPushSubscriptionSuccess,
					deleteWebPushSubscriptionFailure,
					() => socketConnector.deleteWebPushSubscription(deviceId)
				)
			);
		}
	};
