import SocketConnector from '@src/api/SocketConnector';
import {useCallback, useEffect} from 'react';
import {IExportTasksFinished} from '@tehzor/tools/interfaces/export/IExportTasksFinished';
import {IExportTasksFailed} from '@tehzor/tools/interfaces/export/IExportTasksFailed';
import useAppDispatch from '../../../core/hooks/useAppDispatch';
import {updateDefferedExportTask} from '@src/store/modules/defferedTasks/export/slice';
import {ExportTaskStatus} from '@tehzor/tools/interfaces/export/ExportTaskStatus';
import {queryClient} from '@src/api/QueryClient';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import {problemsDocumentsStatsQueryKeys} from '@src/api/cache/problemsDocumentsStats/keys';
import {checksDocumentsStatsQueryKeys} from '@src/api/cache/checksDocumentsStats/keys';

const socketConnector = SocketConnector.instance;
export const useExportEventHandler = () => {
	const dispatch = useAppDispatch();

	const handleFinishedTask = useCallback(
		(message: IExportTasksFinished) => {
			dispatch(
				updateDefferedExportTask({
					taskId: message.taskId,
					status: ExportTaskStatus.FINISHED,
					message
				})
			);
			if (message?.document) {
				void queryClient.invalidateQueries({queryKey: documentsQueryKeys.lists()});
				void queryClient.invalidateQueries({
					queryKey: problemsDocumentsStatsQueryKeys.lists()
				});
				void queryClient.invalidateQueries({
					queryKey: checksDocumentsStatsQueryKeys.lists()
				});
			}
		},
		[dispatch]
	);
	const handleFailedTask = useCallback(
		(message: IExportTasksFailed) => {
			dispatch(
				updateDefferedExportTask({
					taskId: message.taskId,
					status: ExportTaskStatus.FAILED,
					message
				})
			);
		},
		[dispatch]
	);

	useEffect(() => {
		socketConnector.subscribeOnExportEvents(handleFinishedTask, handleFailedTask);
		return () => socketConnector.unsubscribeOnExportEvents();
	}, [handleFinishedTask, handleFailedTask]);
};
