import {makeSpacesExportRequest} from '@src/api/backend/spaces';
import {IExportSpacesParams} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useMutation} from '@tanstack/react-query';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

export const useExportSpacesMutation = (objectId: string) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation({
		mutationKey: spacesQueryKeys.exportById(objectId),
		mutationFn: ({templateId, createDocument, email}: IExportSpacesParams) =>
			makeSpacesExportRequest(templateId, objectId, createDocument, email),
		onSuccess: (response, {email}: IExportSpacesParams) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
