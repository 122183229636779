import {useCallback} from 'react';
import {useQuery} from '@tanstack/react-query';

import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetExportTemplatesResponse} from '@src/api/backend/exportTemplates/get';
import {exportTemplatesQueryKeys} from '@src/api/cache/exportTemplates/keys';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import useAppSelector from '@src/core/hooks/useAppSelector';

import {
	extractExportTemplateById,
	extractExportTemplatesFromTargetObject,
	extractExportTemplatesAsArrayWithoutPdf,
	extractExportTemplatesFromGeneralRegisters
} from './selectors';
import {extractUserCompanies} from '@src/store/modules/auth/profile/selectors';

export const useFetchExportTemplates = <T = IGetExportTemplatesResponse>(
	select?: (data: IGetExportTemplatesResponse) => T,
	enabled?: boolean
) =>
	useQuery<IGetExportTemplatesResponse, IError, T>({
		queryKey: exportTemplatesQueryKeys.list(),
		meta: {error: 'при загрузке шаблонов экспорта'},
		enabled,
		select
	});

export const useExportTemplatesAsArray = (objectId: string, enabled?: boolean) => {
	const userCompaniesIds = useAppSelector(extractUserCompanies);
	const {data: object} = useObject(objectId === 'all' ? undefined : objectId);

	const selector = useCallback(
		(data: IGetExportTemplatesResponse): IExportTemplate[] => {
			if (object) {
				return extractExportTemplatesFromTargetObject(data, object);
			}
			return extractExportTemplatesFromGeneralRegisters(data, userCompaniesIds);
		},
		[object?.id, userCompaniesIds?.length]
	);

	return useFetchExportTemplates(selector, enabled);
};

export const useGetExportTemplatesAsArrayWithoutPdf = (enabled?: boolean) =>
	useFetchExportTemplates(extractExportTemplatesAsArrayWithoutPdf, enabled);

export const useExtractExportTemplateById = (templateId?: string, enabled?: boolean) => {
	const selector = useCallback(
		(data: IGetExportTemplatesResponse) => extractExportTemplateById(data, templateId),
		[templateId]
	);
	return useFetchExportTemplates(selector, enabled);
};
