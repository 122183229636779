import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {
	ICheckListsSelected,
	IDefferedExportResponse,
	IExportCheckListsRequest
} from '@tehzor/tools/interfaces/export';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт чеклистов
 */
export const makeCheckListsExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	selected: ICheckListsSelected[]
): Promise<IDefferedExportResponse> => {
	const params: IExportCheckListsRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			selected
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		'check-lists/export',
		params
	);
	return response.data;
};
