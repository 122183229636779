import {useDocumentsPaginateArray} from '@src/core/hooks/queries/documents/hooks';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {
	IDocumentsSortState,
	documentsActions
} from '@src/store/modules/settings/pages/documents/reducers';
import {extractDocumentsPageSettings} from '@src/store/modules/settings/pages/documents/selectors';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {memo, useCallback, useMemo} from 'react';
import {desktopColumns} from './columns.desktop';
import {mobileColumns} from './columns.mobile';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';

interface IDesktopTableProps {
	offset?: number;
	total?: number;
}

const pageSizes = [10, 20, 50, 100];

export const Table = memo(({offset = 0, total = 0}: IDesktopTableProps) => {
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();

	const {changeSort, changePageSize, changeOffset} = documentsActions;

	const {pageSize, sort} = useAppSelector(extractDocumentsPageSettings);
	const pageCount = useMemo(() => Math.ceil(total / pageSize), [total, pageSize]);
	const currentPage = useMemo(() => Math.floor(offset / pageSize), [offset, pageSize]);

	const {data: documents} = useDocumentsPaginateArray();

	const isLargeTablet = useIsLargeTablet();
	const columns = useMemo(() => {
		if (isLargeTablet) {
			return desktopColumns;
		}
		return mobileColumns;
	}, [isLargeTablet]);

	const handleRowClick = useCallback((document: IDocument) => {
		pushPath(`/documents/${document.id}`);
	}, []);

	const handleSortChange = useCallback((value: IDocumentsSortState) => {
		dispatch(changeSort(value));
	}, []);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (offset !== newOffset) {
				dispatch(changeOffset(newOffset));
			}
		},
		[pageSize, offset]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			const newOffset = Math.floor(offset / value);

			dispatch(changePageSize(value));
			dispatch(changeOffset(newOffset));
		},
		[offset]
	);

	return (
		<div>
			<Plate
				className="documents-page__plate"
				withoutPadding
			>
				{documents && (
					<EntitiesTable
						sort={sort}
						columns={columns}
						data={documents}
						headVisible={isLargeTablet}
						onRowClick={handleRowClick}
						onSortChange={handleSortChange}
					/>
				)}
			</Plate>
			<PaginationAndSize
				pagination={
					<Pagination
						className="documents-page__pagination"
						pageCount={pageCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						className="documents-page__page-size"
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</div>
	);
});
