import * as types from '@src/constants/notifications/messages';
import SocketConnector from '../../api/SocketConnector';
import IError from '@tehzor/tools/interfaces/IError';
import INotificationMessage from '@tehzor/tools/interfaces/INotificationMessage';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';

const socketConnector = SocketConnector.instance;

const subscribeOnNotificationsRequest = () => ({
	type: types.SUBSCRIBE_ON_NOTIFICATIONS_REQUEST
});

const subscribeOnNotificationsSuccess = () => ({
	type: types.SUBSCRIBE_ON_NOTIFICATIONS_SUCCESS
});

const subscribeOnNotificationsFailure = (error: IError) => ({
	type: types.SUBSCRIBE_ON_NOTIFICATIONS_FAILURE,
	payload: {...error}
});

/**
 * Подписывается на получение уведомлений
 *
 * @return {Promise}
 */
export const subscribeOnNotifications = (): AppThunkAction<Promise<unknown>> => dispatch =>
	dispatch(
		createApiAction(
			subscribeOnNotificationsRequest,
			subscribeOnNotificationsSuccess,
			subscribeOnNotificationsFailure,
			socketConnector.subscribeOnNotifications
		)
	);

const unsubscribeFromNotificationsRequest = () => ({
	type: types.UNSUBSCRIBE_FROM_NOTIFICATIONS_REQUEST
});

const unsubscribeFromNotificationsSuccess = () => ({
	type: types.UNSUBSCRIBE_FROM_NOTIFICATIONS_SUCCESS
});

const unsubscribeFromNotificationsFailure = (error: IError) => ({
	type: types.UNSUBSCRIBE_FROM_NOTIFICATIONS_FAILURE,
	payload: {...error}
});

/**
 * Отдписывается от получения уведомлений
 *
 * @return {Promise}
 */
export const unsubscribeFromNotifications = (): AppThunkAction<Promise<unknown>> => dispatch =>
	dispatch(
		createApiAction(
			unsubscribeFromNotificationsRequest,
			unsubscribeFromNotificationsSuccess,
			unsubscribeFromNotificationsFailure,
			socketConnector.unsubscribeFromNotifications
		)
	);

export interface IGetNotificationMessagesResponse {
	messages: INotificationMessage[];
	offset: number;
	limit: number;
	total: number;
}

const getMessagesRequest = () => ({
	type: types.GET_NOTIFICATION_MESSAGES_REQUEST
});

const getMessagesSuccess = (response: IGetNotificationMessagesResponse) => ({
	type: types.GET_NOTIFICATION_MESSAGES_SUCCESS,
	payload: response
});

const getMessagesFailure = (error: IError) => ({
	type: types.GET_NOTIFICATION_MESSAGES_FAILURE,
	payload: {...error}
});

/**
 * Получает список сообщений-уведомлений
 *
 * @returns {{}}
 */
export const getMessages = (): AppThunkAction<Promise<unknown>> => (dispatch, getState) =>
	dispatch(
		createApiAction(getMessagesRequest, getMessagesSuccess, getMessagesFailure, () => {
			const state = getState();
			const {offset, limit} = state.notifications.messages;

			return socketConnector.getNotificationMessages(offset, limit);
		})
	);

const deleteMessageRequest = () => ({
	type: types.DELETE_NOTIFICATION_MESSAGE_REQUEST
});

const deleteMessageSuccess = (id: string) => ({
	type: types.DELETE_NOTIFICATION_MESSAGE_SUCCESS,
	payload: {id}
});

const deleteMessageFailure = (error: IError) => ({
	type: types.DELETE_NOTIFICATION_MESSAGE_FAILURE,
	payload: {...error}
});

/**
 * Помечает сообщение как прочитанное
 *
 * @param {string} id id сообщения
 * @returns {{}}
 */
export const deleteMessage =
	(id: string): AppThunkAction<Promise<unknown>> =>
	dispatch =>
		dispatch(
			createApiAction(
				deleteMessageRequest,
				() => deleteMessageSuccess(id),
				deleteMessageFailure,
				() => socketConnector.deleteNotificationMessage(id)
			)
		);

const deleteAllMessagesRequest = () => ({
	type: types.DELETE_ALL_NOTIFICATION_MESSAGES_REQUEST
});

const deleteAllMessagesSuccess = () => ({
	type: types.DELETE_ALL_NOTIFICATION_MESSAGES_SUCCESS
});

const deleteAllMessagesFailure = (error: IError) => ({
	type: types.DELETE_ALL_NOTIFICATION_MESSAGES_FAILURE,
	payload: {...error}
});

/**
 * Помечает все сообщения как прочитанные
 *
 * @returns {{}}
 */
export const deleteAllMessages = (): AppThunkAction<Promise<unknown>> => dispatch =>
	dispatch(
		createApiAction(
			deleteAllMessagesRequest,
			deleteAllMessagesSuccess,
			deleteAllMessagesFailure,
			() => socketConnector.deleteAllNotificationMessages()
		)
	);

/**
 * Очищает сообщения в store
 */
export const clearNotificationMessages = () => ({
	type: types.CLEAR_NOTIFICATION_MESSAGES
});
