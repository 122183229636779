import {useCallback} from 'react';
import EntitiesFilters, {
	CreatedByFilter,
	CreationDateFilter,
	DateFilter,
	ObjectsFilter
} from '@src/components/EntitiesFilters';
import {StatusesFilter} from './StatusesFilter';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IWarrantyClaimsFiltersState} from '@src/store/modules/settings/pages/warrantyClaims/slices';
import {useTranslation} from 'react-i18next';
import {SpaceOwnersFilter} from '@src/components/EntitiesFilters/components/SpaceOwnersFilter';
import {WarrantyClaimsSearch} from '../WarrantyClaimsSearch/WarrantyClaimsSearch';
import {ICustomFieldSetting} from '@tehzor/tools/interfaces/fields';

interface IClaimsFiltersProps {
	objectId?: string;
	customFieldsSettings?: Record<string, ICustomFieldSetting>;
}

const ClaimsFilters = ({objectId = 'all', customFieldsSettings}: IClaimsFiltersProps) => {
	const {t} = useTranslation();
	const {state, dispatch} = useEntitiesFiltersCtx<IWarrantyClaimsFiltersState>();
	const onRegistrationDateFilterChange = useCallback(
		(dateFrom?: Date | null, dateTo?: Date | null) => {
			dispatch({registrationDateFrom: dateFrom, registrationDateTo: dateTo});
		},
		[dispatch]
	);

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="warranty-claims"
			className="w-claims-page__entities-filters"
		>
			<div className="w-claims-page__search">
				<WarrantyClaimsSearch
					customFieldsSettings={customFieldsSettings}
					objectId={objectId}
				/>
			</div>

			{objectId === 'all' && (
				<ObjectsFilter
					value={state?.objects}
					label={t('entitiesFilters.objectsFilter.label')}
				/>
			)}

			<StatusesFilter statuses={state.statuses} />

			<DateFilter
				from={state?.registrationDateFrom}
				to={state?.registrationDateTo}
				onChange={onRegistrationDateFilterChange}
				label={t('entitiesFilters.registrationDateFilter.label')}
			/>

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
				label={t('entitiesFilters.CreatedByFilter.label')}
			/>

			<SpaceOwnersFilter
				owners={state?.spaceOwners}
				label="Заявитель"
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
				label={t('entitiesFilters.CreationDateFilter.label')}
			/>
		</EntitiesFilters>
	);
};

export default ClaimsFilters;
