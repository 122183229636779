import {useMutation} from '@tanstack/react-query';
import {makeCheckExportRequest} from '@src/api/backend/check';
import {addErrorToast} from '@tehzor/ui-components';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

interface IExportCheckParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId: string;
	checkId: string;
	problems?: string[];
	inspections?: string[];
}

export const useExportCheck = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation({
		mutationKey: checksQueryKeys.exportOne(),
		mutationFn: (params: IExportCheckParams) => {
			const {templateId, createDocument, email, objectId, checkId, problems, inspections} =
				params;
			return makeCheckExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				checkId,
				problems,
				inspections
			);
		},
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
