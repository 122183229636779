import {makeCheckListExportRequest} from '@src/api/backend/checkList/export';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {addErrorToast} from '@tehzor/ui-components';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

interface IExportCheckParams {
	templateId: string;
	objectId: string;
	spaceId: string;
	checkListId: string;
	problems?: string[];
	createDocument?: boolean;
	email?: string;
}

export const useExportCheckList = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation<IDefferedExportResponse, IError, IExportCheckParams>({
		mutationKey: checkListsQueryKeys.exportOne(),
		mutationFn: (params: IExportCheckParams) => {
			const {templateId, objectId, spaceId, checkListId, problems, createDocument, email} =
				params;
			return makeCheckListExportRequest(
				templateId,
				objectId,
				spaceId,
				checkListId,
				problems,
				createDocument,
				email
			);
		},
		onSuccess: (response: IDefferedExportResponse, params: IExportCheckParams) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
