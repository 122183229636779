import {IBriefObject} from '@tehzor/tools/interfaces/objects/IBriefObject';
import {InlineButton} from '@tehzor/ui-components';
import React, {useState} from 'react';

interface ObjectsListProps {
	objects: IBriefObject[];
}

const OBJECTS_COUNT = 5;
const ObjectsList: React.FC<ObjectsListProps> = ({objects}) => {
	const [expanded, setExpanded] = useState(false);

	const toggleExpansion = () => setExpanded(!expanded);
	const showTagsCountLeft = () => {
		if (!objects) return '';
		return ` +${objects.length - OBJECTS_COUNT}`;
	};
	if (!objects?.length) {
		return null;
	}

	return (
		<div className="document-page__objects-list">
			{objects.slice(0, expanded ? objects.length : OBJECTS_COUNT).map(object => (
				<p
					className="document-page__entity-paragraph--objects"
					key={object.id}
				>
					{object.name}
				</p>
			))}
			{objects.length > OBJECTS_COUNT && (
				<InlineButton
					type="filled"
					className="document-page__objects-more-btn"
					label={expanded ? 'Скрыть' : showTagsCountLeft()}
					onClick={toggleExpansion}
				/>
			)}
		</div>
	);
};

export default ObjectsList;
