import {useMutation} from '@tanstack/react-query';
import {makeCheckListsExportRequest} from '@src/api/backend/checkLists';
import {addErrorToast} from '@tehzor/ui-components';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {useTranslation} from 'react-i18next';
import {ICheckListsSelected} from '@tehzor/tools/interfaces/export';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

interface IExportCheckParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	selected: ICheckListsSelected[];
}

export const useExportCheckListsMutation = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation({
		mutationKey: checkListsQueryKeys.export(),
		mutationFn: (params: IExportCheckParams) => {
			const {selected, templateId, email, createDocument} = params;
			return makeCheckListsExportRequest(templateId, createDocument, email, selected);
		},
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
