import {makeOwnerAcptExportRequest} from '@src/api/backend/ownerAcceptance';
import {addErrorToast} from '@tehzor/ui-components';
import {useMutation} from '@tanstack/react-query';
import {IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

interface IExportOwnerAcceptanceParams {
	templateId: string;
	objectId: string;
	acceptanceId: string;
	problems?: string[];
	createDocument?: boolean;
	email?: string;
}

export const useExportOwnerAcceptance = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation<IDefferedExportResponse, Error, IExportOwnerAcceptanceParams>({
		mutationKey: ownerAcceptancesQueryKeys.exportOne(),
		mutationFn: params => {
			const {templateId, objectId, acceptanceId, problems, createDocument, email} = params;
			return makeOwnerAcptExportRequest(
				templateId,
				objectId,
				acceptanceId,
				problems,
				createDocument,
				email
			);
		},
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
