import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IDefferedExportResponse, IExportObjectRequest} from '@tehzor/tools/interfaces/export';
import getEmailsArray from '@src/utils/getEmailsArray';

export const makeSpacesExportRequest = async (
	templateId: string,
	objectId: string,
	createDocument?: boolean,
	email?: string
): Promise<IDefferedExportResponse> => {
	const params: IExportObjectRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		'objects/export',
		params
	);

	return response.data;
};
