import {useMutation, useQueryClient} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';
import {
	makeWarrantyClaimStatusEditRequest,
	IEditWarrantyClaimStatusResponse,
	IEditWarrantyClaimStatusRequest
} from '@src/api/backend/warrantyClaim';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IEditWarrantyClaimStatusPayload = IEditWarrantyClaimStatusResponse;

export const useEditWarrantyClaimStatus = () => {
	const queryClient = useQueryClient();

	return useMutation<IEditWarrantyClaimStatusResponse, IError, IEditWarrantyClaimStatusRequest>({
		mutationKey: warrantyClaimsQueryKeys.editStatus(),
		mutationFn: ({objectId, claimId, statusId}) =>
			makeWarrantyClaimStatusEditRequest({objectId, claimId, statusId}),
		onSuccess: response => {
			void queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.lists()});
			void queryClient.invalidateQueries({
				queryKey: [...warrantyClaimsQueryKeys.detail(response.id), response.objectId]
			});
			if (response.links?.spaceId) {
				void queryClient.invalidateQueries({
					queryKey: spacesQueryKeys.spaceWarrantyClaims(
						response.objectId,
						response.links.spaceId
					)
				});
			}
		},
		onError: () => {
			addErrorToast({title: t('toast.editWarrantyClaimStatus.error.title')});
		}
	});
};
