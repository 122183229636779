import {TFunction} from 'i18next';

export const createProblemErrorMessages = (t: TFunction) => ({
	categoryId: t('components.editableFields.category.error'),
	plannedFixDate: {
		empty: t('components.editableFields.editableDate.error'),
		incorrect: t('components.editableFields.editableDate.incorrect')
	},
	reason: t('components.editableFields.SearchableProblemReason.error'),
	location: t('components.editableFields.location.error'),
	floor: t('components.editableFields.floor.error'),
	inspectors: t('components.editableProblem.inspectors.errorLabel'),
	performers: t('components.editableProblem.performers.errorLabel'),
	description: t('components.editableFields.description.error'),
	prescription: t('components.editableFields.prescription.error'),
	attachments: t('components.editableFields.attachments.error'),
	problemTags: t('components.editableFields.problemTags.error'),
	contractId: t('components.editableFields.contracts.error'),
	customFields: t('components.editableFields.text.error')
});
