import {IWarrantyClaimStatus} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetWarrantyClaimStatusesResponse = INormalizedData<IWarrantyClaimStatus>;

/**
 * Возвращает список статусов гарантийных обращений
 */
export const requestWarrantyClaimStatuses = async () => {
	const response = await httpRequests.withToken.get<IGetWarrantyClaimStatusesResponse>(
		'warranty-claims-statuses'
	);
	return response.data;
};
