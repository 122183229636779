import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IDefferedExportResponse, IExportInspectionRequest} from '@tehzor/tools/interfaces/export';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт осмотра
 */
export const makeInspectionExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	inspectionId: string
): Promise<IDefferedExportResponse> => {
	const params: IExportInspectionRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		`inspections/export/${inspectionId}`,
		params
	);
	return response.data;
};
