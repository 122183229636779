import {extractPendingDefferedExportTasks} from '@src/store/modules/defferedTasks/export/selectors';
import {useDefferedExportTasksForCurrentUser} from '../../../core/hooks/queries/defferedFileExport/hooks';
import useAppSelector from '../../../core/hooks/useAppSelector';
import useAppDispatch from '../../../core/hooks/useAppDispatch';
import {updateDefferedExportTask} from '@src/store/modules/defferedTasks/export/slice';
import {IExportTasksFinished} from '@tehzor/tools/interfaces/export/IExportTasksFinished';
import {IExportTasksFailed} from '@tehzor/tools/interfaces/export/IExportTasksFailed';
import {useEffect, useRef} from 'react';

export const useProcessingMissedExportEvents = () => {
	const dispatch = useAppDispatch();
	const localPendingDefferedTasks = useAppSelector(extractPendingDefferedExportTasks);
	const {data: defferedTasks} = useDefferedExportTasksForCurrentUser();
	const processed = useRef(false);

	/**
	 * Сравниваем отложенные задачи cо статусом pending в redux и задачи которые пришли в ответе с сервера
	 * Если есть задачи которые на сервере сменили статус pending на другой, то обновляем их информацию в redux
	 * Нужно для того, чтобы при перезагрузках страниц/закрытии приложения/разлогине актуализировать начатые задачи
	 */
	useEffect(() => {
		if (processed.current || !defferedTasks) return;

		const tasksKeys = Object.keys(localPendingDefferedTasks);

		if (!tasksKeys.length) {
			processed.current = true;
			return;
		}
		tasksKeys.forEach(taskId => {
			const changedTask = defferedTasks.find(
				task =>
					task.id === taskId && task.statusId !== localPendingDefferedTasks[taskId].status
			);
			if (changedTask) {
				dispatch(
					updateDefferedExportTask({
						taskId: changedTask.id,
						status: changedTask.statusId,
						message: changedTask.data as unknown as
							| IExportTasksFinished
							| IExportTasksFailed
					})
				);
			}
		});
		processed.current = true;
	}, [defferedTasks, dispatch, localPendingDefferedTasks]);
};
