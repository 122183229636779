import {
	DownloadableFile,
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	Plate
} from '@tehzor/ui-components';
import {memo} from 'react';
import {IPreparedContract} from '../interfaces/IPreparedContract';
import {requestGetContractAttachment} from '@src/api/backend/contract/getAttachment';
import saveOrOpenBlob from '@tehzor/tools/utils/saveOrOpenBlob';
import {ExpandableMobileInfo} from '@src/components/ExpandableMobileInfo';

interface IContractInfoProps {
	contract: IPreparedContract;
}

const nameIcon = <i className="tz-name-20" />;
const numberIcon = <i className="tz-number-20" />;
const attachmentIcon = <i className="tz-attach-20" />;
const createdIcon = <i className="tz-created-20" />;
const modifiedIcon = <i className="tz-modified-20" />;

export const ContractMobileInfo = memo(({contract}: IContractInfoProps) => {
	const handleClick = async (fileName?: string, fileKey?: string) => {
		if (!fileKey || !fileName) {
			return;
		}
		const res = await requestGetContractAttachment(contract.id, fileKey);
		saveOrOpenBlob(res.fileContent, fileName);
	};
	return (
		<Plate className="contract__info">
			<EntityGrid withBorders>
				<EntityGridItem
					className={{
						root: 'contract__info-item',
						header: 'contract__info-item-title'
					}}
					label="Название"
					icon={nameIcon}
					fullRow
				>
					{contract.name}
				</EntityGridItem>

				{contract.number && (
					<EntityGridItem
						className={{
							root: 'contract__info-item',
							header: 'contract__info-item-title'
						}}
						label="Номер"
						icon={numberIcon}
						fullRow
					>
						{contract.number}
					</EntityGridItem>
				)}

				{contract.attachments?.length ? (
					<EntityGridItem
						className={{
							root: 'contract__info-item',
							header: 'contract__info-item-title'
						}}
						label="Вложения"
						icon={attachmentIcon}
						fullRow
					>
						{contract.attachments?.map(att => {
							if (!att?.fileName || !att?.fileType) {
								return null;
							}

							return (
								<DownloadableFile
									className={{root: 'contract__downloadable-content'}}
									key={att.id}
									name={att.fileName}
									type={att.fileType}
									size={Number(att.fileSize)}
									onClick={() => handleClick(att.fileName, att.fileKey)}
								/>
							);
						})}
					</EntityGridItem>
				) : null}
			</EntityGrid>

			<ExpandableMobileInfo>
				<EntityGrid withBorders>
					<EditorInfo
						className="contract-info__createdAt"
						date={contract.createdAt}
						user={contract.createdBy}
						label="Создано"
						icon={createdIcon}
						fullRow
					/>

					{(contract.modifiedAt || contract.modifiedBy) && (
						<EditorInfo
							className="contract-info__modifiedAt"
							date={contract.modifiedAt}
							user={contract.modifiedBy}
							label="Изменено"
							icon={modifiedIcon}
							fullRow
						/>
					)}
				</EntityGrid>
			</ExpandableMobileInfo>
		</Plate>
	);
});
