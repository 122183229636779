import {Responsibles} from '@src/components/viewFields/Responsibles/Responsibles';
import {useInspectionResponsiblePermissions} from '@src/core/hooks/permissions/useInspectionResponsiblePermissions';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDelegationHistoryAsArrayByInspection} from '@src/core/hooks/queries/delegationHistory/hooks';
import {useEditInspectionPerformers} from '@src/core/hooks/mutations/inspection/useEditInspectionPerformers';

interface IInspectionPerformersProps {
	inspection: ILinkedInspection;
	settings?: IEntitySettings;
}

export const InspectionPerformers = ({inspection, settings}: IInspectionPerformersProps) => {
	const {t} = useTranslation();
	const {id, objectId, stage, performersActiveGroup, performersInitialGroup, performers} =
		inspection;
	const {data: histories} = useDelegationHistoryAsArrayByInspection(objectId, id);
	const {canEditPerformers, onlyActiveGroup} = useInspectionResponsiblePermissions(
		objectId,
		inspection
	);
	const {mutateAsync: editInspectionPerformers} = useEditInspectionPerformers();
	const ruleParams: IRuleParams = useMemo(
		() => ({
			categoryId: inspection.categoryId,
			planId: inspection.plan?.id
		}),
		[inspection.categoryId, inspection.plan?.id]
	);

	const handleChange = useCallback(
		async (users: string[], group?: string) => {
			await editInspectionPerformers({
				objectId,
				inspectionId: id,
				respUsers: users,
				activeGroup: group
			});
		},
		[objectId, id]
	);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			workingGroupType={WorkingGroupTypeId.PERFORMERS}
			respUsers={performers}
			activeGroup={performersActiveGroup}
			initialGroup={performersInitialGroup}
			canEditResponsibles={canEditPerformers}
			onlyActiveGroup={onlyActiveGroup}
			ruleParams={ruleParams}
			histories={histories}
			required={settings?.builtin.performers.required}
			label={t('inspectionPage.info.performers.label')}
			delegationDialogLabel={t('components.delegationDialog.performers.title')}
			historyAutomaticActionLabelIn={t(
				'inspectionPage.info.performers.historyAutomaticActionLabelIn'
			)}
			historyAutomaticActionLabelTitle={t(
				'inspectionPage.info.performers.historyAutomaticActionLabelTitle'
			)}
			historyUserActionLabelIn={t('inspectionPage.info.performers.historyUserActionLabelIn')}
			historyUserActionLabelTitle={t(
				'inspectionPage.info.performers.historyUserActionLabelTitle'
			)}
			onResponsiblesChange={handleChange}
		/>
	);
};
