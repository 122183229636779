import {useMutation} from '@tanstack/react-query';
import {IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {problemRepliesQueryKeys} from '@src/api/cache/problemReplies/keys';
import {makeProblemReplyExportRequest} from '@src/api/backend/reply';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

interface IExportProblemRepliesParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId: string;
	commentId: string;
}

export const useExportProblemReplies = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation<IDefferedExportResponse, Error, IExportProblemRepliesParams>({
		mutationKey: problemRepliesQueryKeys.export(),
		mutationFn: params => {
			const {templateId, createDocument, email, objectId, commentId} = params;
			return makeProblemReplyExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				commentId
			);
		},
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
