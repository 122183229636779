import {useCallback} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import WarrantyClaimsPage from './WarrantyClaimsPage';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {
	IWarrantyClaimsFiltersState,
	changeFilters,
	clearFilters,
	changeOffset,
	changeSearchBy
} from '@src/store/modules/settings/pages/warrantyClaims/slices';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import {WarrantyClaimSearchField} from '@tehzor/tools/enums/WarrantyClaimSearchField';

/**
 * Обёртка над страницей обращений
 * необходима для того чтобы при переходе от одной страницы к другой отрабатывали все методы жизненного цикла
 *
 * @constructor
 */
const WarrantyClaimsPageWrap = (
	props: JSX.IntrinsicAttributes & {className?: string | undefined}
) => {
	const dispatch = useAppDispatch();
	const {objectId} = useStrictParams<{objectId: string}>();

	const sectionsMenu = useObjectSectionsMenu(objectId);
	const {filters} = useAppSelector(s => extractWarrantyClaimsPageSettings(s, objectId));

	const applyFilters = useCallback(
		(value: IWarrantyClaimsFiltersState) => {
			dispatch(changeFilters({objectId, filters: value}));
			dispatch(changeOffset({objectId, offset: 0}));
		},
		[objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId}));
		dispatch(
			changeSearchBy({objectId: 'all', searchBy: WarrantyClaimSearchField.CLAIM_NUMBER})
		);
	}, [objectId]);

	return (
		<EntitiesFiltersProvider
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<WarrantyClaimsPage
				key={objectId}
				className={sectionsMenu.length ? 'page-cont_extra-top-margin' : undefined}
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};

export default WarrantyClaimsPageWrap;
