import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {
	IDefferedExportResponse,
	IExportInternalAcceptancesFilters,
	IExportInternalAcceptancesRequest
} from '@tehzor/tools/interfaces/export';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт внутренних приемок
 */
export const makeInternalAcceptancesExportRequest = async (
	templateId: string,
	email: string | undefined,
	objectId?: string,
	filters?: Omit<IExportInternalAcceptancesFilters, 'selected'>,
	sort?: Record<string, boolean>,
	selected?: string[],
	createDocument?: boolean
): Promise<IDefferedExportResponse> => {
	const params: IExportInternalAcceptancesRequest = {
		templateId,
		timezone: getTimezone(),
		createDocument,
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId,
			filters: {
				...filters,
				selected
			},
			sort: convertSortParam(sort)
		}
	};

	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		'internal-acceptances/export',
		params
	);
	return response.data;
};
