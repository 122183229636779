import {useCallback} from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {Button, IconButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useExportCheckList} from '@src/core/hooks/mutations/checkLists/useExportCheckList';
import {t} from 'i18next';

const availableDestinations = [ExportTemplatesDestinationId.CHECKLIST];

interface ICheckListExportDialog {
	objectId: string;
	spaceId: string;
	checkListId: string;
}

const CheckListExportDialog = (props: ICheckListExportDialog) => {
	const {objectId, spaceId, checkListId} = props;
	const [isOpen, toggleOpen] = useToggle(false);
	const {mutateAsync: exportCheckList} = useExportCheckList();

	const exportList = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			const params = {
				email,
				templateId: template.id,
				objectId,
				spaceId,
				checkListId,
				createDocument
			};

			await exportCheckList({...params});
		},
		[objectId, spaceId, checkListId]
	);

	const isDesktop = useIsDesktop();

	return (
		<>
			{isDesktop ? (
				<Button
					type="accent-blue"
					leftIcon={<i className="tz-export-20" />}
					label={t('checkListPage.actions.checkListsExportDialog.button.label')}
					onClick={toggleOpen}
				/>
			) : (
				<IconButton onClick={toggleOpen}>
					<i className="tz-export-24" />
				</IconButton>
			)}
			<ExportDialog
				objectId={objectId}
				pageKey="check-list"
				destinations={availableDestinations}
				isOpen={isOpen}
				onExport={exportList}
				onClose={toggleOpen}
			/>
		</>
	);
};

export default CheckListExportDialog;
