import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {
	IDefferedExportResponse,
	IExportInspectionsFilters,
	IExportInspectionsRequest
} from '@tehzor/tools/interfaces/export';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт осмотров
 */
export const makeInspectionsExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string | undefined,
	filters: Omit<IExportInspectionsFilters, 'selected'> | undefined,
	sort: Record<string, boolean> | undefined,
	selected: string[] | undefined
): Promise<IDefferedExportResponse> => {
	const params: IExportInspectionsRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId: objectId === 'all' ? undefined : objectId, // TODO Выпилить этот костыль,
			filters: {
				...filters,
				selected
			},
			sort: convertSortParam(sort)
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		'inspections/export',
		params
	);
	return response.data;
};
