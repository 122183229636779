import useAppSelector from '@src/core/hooks/useAppSelector';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {
	extractFailedDefferedExportTasks,
	extractFinishedDefferedExportTasks
} from '@src/store/modules/defferedTasks/export/selectors';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {toggleToastShown} from '@src/store/modules/defferedTasks/export/slice';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {addErrorToast} from '@tehzor/ui-components';
import {formS3FileUrl} from '@src/utils/formS3FileUrl';

export const useShowExportToasts = () => {
	const finishedTasks = useAppSelector(extractFinishedDefferedExportTasks);
	const failedTasks = useAppSelector(extractFailedDefferedExportTasks);
	const dispatch = useAppDispatch();
	const {t} = useTranslation();

	// Обработка успешного экспорта
	useEffect(() => {
		const tasks = Object.values(finishedTasks);
		// Если нет завершённых задач, выходим
		if (!tasks.length) return;

		// Перебираем задачи и показываем тосты
		tasks.forEach(({message, toastShown, email}) => {
			if (!message || toastShown) return;

			const {file, document, taskId} = message;

			// Добавляем тост
			addExportToast({
				title: email
					? t('toast.export.send.success.title')
					: t('toast.export.success.title'),
				url: formS3FileUrl(file.s3Key, file.mimeType),
				fileName: file.name,
				document
			});

			// Отмечаем, что тост был показан
			dispatch(toggleToastShown({taskId}));
		});
	}, [finishedTasks, dispatch, t]);

	// Обработка не успешного экспорта
	useEffect(() => {
		const tasks = Object.values(failedTasks);
		// Если нет завершённых задач, выходим
		if (!tasks.length) return;

		// Перебираем задачи и показываем тосты
		tasks.forEach(({message, toastShown}) => {
			if (!message || toastShown) return;

			const {taskId, error} = message;

			// Добавляем тост и докидываем ошибку в него, если это dev среда, для удобства тестирования
			addErrorToast(
				process.env.NODE_ENV === 'development'
					? {title: t('toast.export.error.title'), description: error}
					: {title: t('toast.export.error.title')}
			);

			// Отмечаем, что тост был показан
			dispatch(toggleToastShown({taskId}));
		});
	}, [dispatch, t, failedTasks]);
};
