import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';

export type IGetWarrantyClaimResponse = ILinkedWarrantyClaim;

/**
 * Отправляет запрос на получение гарантийного обращения
 */
export const requestWarrantyClaim = async (claimId: string, objectId: string) => {
	const params = {objectId};
	const response = await httpRequests.withToken.get<IGetWarrantyClaimResponse>(
		`warranty-claims/${claimId}`,
		{params}
	);

	return response.data;
};
