import {useMutation} from '@tanstack/react-query';
import {IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {makeWorkAcceptanceExportRequest} from '@src/api/backend/workAcceptance';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';

interface IExportWorkAcceptance {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId: string;
	workAcceptanceId: string;
	checkLists?: string[];
	problems?: string[];
}

export const useExportWorkAcceptance = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation<IDefferedExportResponse, Error, IExportWorkAcceptance>({
		mutationKey: workAcceptancesQueryKeys.exportOne(),
		mutationFn: params => {
			const {
				templateId,
				createDocument,
				email,
				objectId,
				workAcceptanceId,
				checkLists,
				problems
			} = params;
			return makeWorkAcceptanceExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				workAcceptanceId,
				checkLists,
				problems
			);
		},
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
