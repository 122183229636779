import {queryClient} from '@src/api/QueryClient';
import {deleteMutation} from '@src/cache/utils/deleteMutation';
import {deleteLinkedLocalProblems} from '../problem/deleteLinkedLocalProblems';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';

export const deleteLocalWarrantyClaim = async (claimId: string) => {
	queryClient.removeQueries({queryKey: [...warrantyClaimsQueryKeys.details(), claimId]});
	await queryClient.refetchQueries({
		queryKey: warrantyClaimsQueryKeys.localList()
	});
	await deleteLinkedLocalProblems('warrantyClaimId', claimId);
	deleteMutation(claimId);
};
