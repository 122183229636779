export const enum dictionaryKeys {
	problemStatuses = 'problemStatuses',
	problemsCritical = 'problemsCritical',
	expiredFilterValues = 'expiredFilterValues',
	objectStages = 'objectStages',
	spaceTypes = 'spaceTypes',
	spaceTypesSingular = 'spaceTypes.singular',
	checkListStatuses = 'checkListStatuses',
	structureTypes = 'structureTypes',
	workAcceptanceTypes = 'workAcceptanceTypes',
	workAcceptanceFrontTypes = 'workAcceptanceFrontTypes',
	ownerAcceptanceStatuses = 'ownerAcceptanceStatuses',
	ownerAcceptanceResolutions = 'ownerAcceptanceResolutions',
	warrantyClaimStatuses = 'warrantyClaimStatuses',
	taskStatuses = 'taskStatuses',
	workAcceptanceStatuses = 'workAcceptanceStatuses',
	meterTypes = 'meterTypes',
	meterTariffs = 'meterTariffs',
	taskTypes = 'taskTypes',
	taskPriorities = 'taskPriorities',
	contractForms = 'contractForms',
	workingGroupTypes = 'workingGroupTypes'
}
