import {IGetExportTemplatesResponse} from '@src/api/backend/exportTemplates/get';
import IExportTemplate, {OutputFileType} from '@tehzor/tools/interfaces/IExportTemplate';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

const extractExportTemplatesAsArray = (data: IGetExportTemplatesResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

export const extractExportTemplatesAsArrayWithoutPdf = (data: IGetExportTemplatesResponse) =>
	extractExportTemplatesAsArray(data).filter(item => item.outputFileType !== OutputFileType.PDF);

export const extractExportTemplatesFromGeneralRegisters = (
	data: IGetExportTemplatesResponse,
	userCompanies: string[] = []
): IExportTemplate[] =>
	extractExportTemplatesAsArray(data).filter(
		item =>
			(item.companyId && userCompanies.includes(item.companyId) && !item.objects?.length) ||
			!item.companyId
	);

export const extractExportTemplatesFromTargetObject = (
	data: IGetExportTemplatesResponse,
	object: IObject
): IExportTemplate[] =>
	extractExportTemplatesAsArray(data).filter(item => {
		if (item.objects?.length) {
			return item.objects.includes(object.id) || !item.companyId;
		}
		return (item.companyId && item.companyId === object.companyId) || !item.companyId;
	});

export const extractExportTemplateById = (
	data: IGetExportTemplatesResponse,
	exportTemplateId: string | undefined
) => {
	if (exportTemplateId) {
		return data.byId[exportTemplateId];
	}
	return undefined;
};
