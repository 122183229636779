import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {oneArgIsTrue} from '@/shared/utils/oneArgIsTrue';
import {ProcessingIcon} from '@tehzor/ui-components';
import {useIsMutating} from '@tanstack/react-query';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPendingDefferedExportTask} from '@src/store/modules/defferedTasks/export/selectors';

export const Processing = () => {
	const someTaskIsPending = useAppSelector(hasPendingDefferedExportTask);
	const isOnline = useOnlineManager();
	// проверка на isOnline необходима, чтобы в случае локального сохранения нарушения не отображался процессинг
	const problemAdding = !!useIsMutating({mutationKey: problemsQueryKeys.add()}) && isOnline;
	const problemEditing = !!useIsMutating({mutationKey: problemsQueryKeys.edit()});

	const inProcess = oneArgIsTrue(problemAdding, problemEditing, someTaskIsPending);

	if (!inProcess) return null;

	return <ProcessingIcon />;
};
